import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import assets from '../../helpers/assets';
import { playSound } from '../../helpers/audio';

let availableCosts = [];

const Token = (props) => {
    const className = `token${props.className ? ` ${props.className}` : ''} ${props.inactive ? ' inactive' : ''} ${props.type === "$" ? "G" : props.type}${props.belongsTo ? " " + props.belongsTo : ""}${props.pulse ? " pulse" : ""}`;
    const tokenRef = useRef(null);

    /**
     * Touch start or mouse down handler
     */
    const handleDragStart = (ev) => {
        if (props.draggable) {
            playSound("button-click");
            const tokenClone = ev.target.cloneNode(true);
            document.getElementById('root').appendChild(tokenClone);
            tokenClone.classList.add("dragging");
            tokenClone.classList.add("token-clone");
            tokenClone.style.top = ((ev.changedTouches ? ev.changedTouches[0].clientY : ev.clientY) - (tokenClone.clientHeight / 2)) + "px";
            tokenClone.style.left = ((ev.changedTouches ? ev.changedTouches[0].clientX : ev.clientX) - (tokenClone.clientWidth / 2)) + "px";
            const newHolding = { tokenClone, type: props.type, belongsTo: props.belongsTo };
            props.setHolding(newHolding);

            // Check which costs I can fill
            Array.prototype.forEach.call(document.getElementsByClassName('project-cost'), (el) => {
                el.classList.remove('can-make-move');
                el.classList.add('cant-make-move');
            });

            availableCosts = [];
            props.projects.forEach((project) => {
                project.costs.forEach((cost, costIndex) => {
                    const typeMatch = cost.tokenTypeId === newHolding.type;
                    const restricted = (cost.spelerId || cost.spelerAltId) && cost.spelerId !== newHolding.belongsTo && cost.spelerAltId !== newHolding.belongsTo;
                    const paymentAlreadyMade = props.tokenDistribution.distribution[project.id].find(c => c.index === costIndex);
                    const valid = typeMatch && !restricted && !paymentAlreadyMade;
                    if (valid) {
                        const costElement = document.getElementById(`project-${project.id}-cost-${costIndex}`);
                        costElement.classList.add("can-make-move");
                        costElement.classList.remove("cant-make-move");
                        availableCosts.push({
                            ref: costElement,
                            projectId: project.id,
                            costIndex,
                        });
                    }
                });
            });
        }
        ev.stopPropagation();
        ev.preventDefault();
    };

    /**
     * Touch end or mouse up handler
     */
    const handleDragStop = (ev) => {
        if (props.holding) {
            if (props.holding.tokenClone) {
                props.holding.tokenClone.remove();
            }
            props.setHolding(null);
            props.setHovering(null);
            Array.prototype.forEach.call(document.getElementsByClassName('project-cost'), (el) => {
                el.classList.remove('can-make-move');
                el.classList.remove('cant-make-move');
                el.classList.remove("hover");
            });
            props.handleDragStop(ev);

            // Ensure no clones are left behind
            Array.prototype.forEach.call(document.getElementsByClassName('token-clone'), (el) => {
                el.remove();
            });
        }
    };

    /**
     * Touchmove or mousemove handler
     */
    const handleDrag = (ev) => {
        if (props.holding) {
            props.holding.tokenClone.style.top = ((ev.changedTouches ? ev.changedTouches[0].clientY : ev.clientY) - (props.holding.tokenClone.clientHeight / 2)) + "px";
            props.holding.tokenClone.style.left = ((ev.changedTouches ? ev.changedTouches[0].clientX : ev.clientX) - (props.holding.tokenClone.clientWidth / 2)) + "px";
        }

        // If there are available costs, check if we're hovering over them
        if (props.holding && Array.isArray(availableCosts) && availableCosts.length) {
            availableCosts.forEach((availableCost, i) => {
                const { x, y } = availableCost.ref.getBoundingClientRect();
                const { clientHeight, clientWidth } = availableCost.ref;
                const topMatch = (ev.changedTouches ? ev.changedTouches[0].clientY : ev.clientY) >= y && (ev.changedTouches ? ev.changedTouches[0].clientY : ev.clientY) <= y + clientHeight;
                const leftMatch = (ev.changedTouches ? ev.changedTouches[0].clientX : ev.clientX) >= x && (ev.changedTouches ?ev.changedTouches[0].clientX : ev.clientX) <= x + clientWidth;
                if (topMatch && leftMatch && !props.hovering) {
                    const project = props.projects.find(p => p.id === availableCost.projectId);
                    if (project) {
                        props.setHovering({ ref: availableCost.ref, cost: project.costs[availableCost.costIndex], costIndex: availableCost.costIndex, projectId: availableCost.projectId, i });
                        availableCost.ref.classList.add("hover");
                    }
                }
                if ((!topMatch || !leftMatch) && props.hovering && props.hovering.i === i) {
                    props.setHovering(null);
                    availableCost.ref.classList.remove("hover");
                }
            });
        }
    };

    useEffect(() => {
        if (tokenRef && tokenRef.current) {
            if (props.draggable) {
                tokenRef.current.addEventListener("mousedown", handleDragStart);
                tokenRef.current.addEventListener("touchstart", handleDragStart);
                document.addEventListener("mouseup", handleDragStop);
                document.addEventListener("touchend", handleDragStop);
                document.addEventListener("mousemove", handleDrag);
                document.addEventListener("touchmove", handleDrag);
            }
        }

        return () => {
            if (tokenRef && tokenRef.current) {
                if (props.draggable) {
                    tokenRef.current.removeEventListener("mousedown", handleDragStart);
                    tokenRef.current.removeEventListener("touchstart", handleDragStart);
                    document.removeEventListener("mousemove", handleDrag);
                    document.removeEventListener("touchmove", handleDrag);
                    document.removeEventListener("mouseup", handleDragStop);
                    document.removeEventListener("touchend", handleDragStop);
                }
            }
        }
    });

    let tokenImgSrc = assets[`${props.type}Token`];
    if (props.inactive && props.type === 'BONUS') {
        tokenImgSrc = assets[`${props.type}TokenInactive`];
    }
    return (
        <div className="token-container">
            <div
                style={{ ...props.style }}
                onClick={(ev) => {
                    if (typeof props.onClick === 'function') {
                        playSound("button-click");
                        props.onClick(ev);
                    }
                }}
                className={className}
                ref={tokenRef}
            >
                <img src={tokenImgSrc} alt="token" />
            </div>
            {props.attachment ? (
                <div className="token__attachment">
                    <span>{props.attachment}</span>
                </div>
            ) : null}
            {props.amount ? (
                <div className="token__amount">
                    <span>{props.amount}</span>
                </div>
            ) : null}
            {props.label ? (
                <div className="token__label">
                    <span>{props.label}</span>
                </div>
            ) : null}
        </div>
    );
};

Token.propTypes = {
    type: PropTypes.oneOf(["$", "A", "K", "OK", "NOK", "BONUS", "CG", "GF", "PF", "IE", "FL", "II", "FF", "FG", "GO", "NL", "EN"]),
    belongsTo: PropTypes.oneOf(["CG", "GF", "PF", "IE", "FL", "II", "FF", "FG"]),
    inactive: PropTypes.bool,
    attachment: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    label: PropTypes.string,
    amount: PropTypes.number,
    draggable: PropTypes.bool,
    setHolding: PropTypes.func,
    setHovering: PropTypes.func,
    handleDragStop: PropTypes.func,
    holding: PropTypes.shape({
        tokenClone: PropTypes.any,
        type: PropTypes.oneOf(["$", "A", "K"]),
        belongsTo: PropTypes.oneOf(["CG", "GF", "PF", "IE", "FL", "II", "FF", "FG"]),
    }),
    pulse: PropTypes.bool,
};
Token.defaultProps = {
    inactive: false,
    belongsTo: null,
    attachment: null,
    onClick: null,
    style: {},
    label: null,
    amount: null,
    draggable: false,
    setHolding: () => {},
    setHovering: () => {},
    handleDragStop: () => {},
    holding: null,
    hovering: null,
    pulse: false,
};

export default connect(({ projects, tokenDistribution }) => ({ projects, tokenDistribution }))(Token);
