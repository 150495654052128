import React from 'react';
import ReactPlayer from 'react-player';
import config from '../../config';
import Token from '../elements/Token';
import { __, getLocale } from '../../i18n';

const IntroductionScene = (props) => {
    return (
        <div className="scene intro-scene">
            <ReactPlayer
                style={{ animation: 'fadeIn .5s ease-in-out' }}
                playing
                controls={false}
                width={'100%'}
                height={'100%'}
                url={getLocale() === "en" ? "https://vimeo.com/648965058" : "https://vimeo.com/648965127"}
                onEnded={() => {
                    props.history.push('/speler-introductie');
                }}
            />
            <Token
                type="OK"
                label={__('skip_video_button_label')}
                onClick={() => {
                    props.history.push('/speler-introductie');
                }}
            />
        </div>
    );
};

export default IntroductionScene;
