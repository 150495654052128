import axios from './Axios';
import getAxiosResult from '../helpers/getAxiosResult';

export default class Game {

    /**
     * Getter to get current gameId.
     * @name gameId
     * @returns {number} Game id
     */
    static get gameId() {
        return parseInt(localStorage.getItem('gameId'), 10);
    }

    /**
     * Getter to get current number.
     * @name roundNumber
     * @returns {number} Round numer
     */
    static get roundNumber() {
        return parseInt(localStorage.getItem('roundNumber'), 10);
    }

    /**
     * Getter to check wether we're currently playing a game or not.
     * @name currentlyPlaying
     * @returns {boolean} playing or not
     */
    static get currentlyPlaying() {
        return !!Game.gameId && !!Game.roundNumber;
    }

    /**
     * Getter to get current player.
     * @name currentPlayer
     * @returns {string} current player
     */
    static get currentPlayer() {
        if (localStorage.getItem('current-player') === 'null') {
            return null;
        }
        return localStorage.getItem('current-player');
    }

    /**
     * Getter to get token distribution.
     * @name tokenDistribution
     * @returns {object} token distribution
     */
    static get tokenDistribution() {
        return JSON.parse(localStorage.getItem("token-distribution"));
    }

    /**
     * Getter to get the player who made a bonus move.
     * @name bonusMoveMadeBy
     * @returns {string}
     */
    static get bonusMoveMadeBy() {
        if (localStorage.getItem("bonus-move-made-by") === "null") {
            return null;
        }
        return localStorage.getItem("bonus-move-made-by");
    }

    /**
     * Getter to get the current game progress.
     * starting -> discussion -> distribution -> submittable -> submitted
     * @name gameProgress
     * @returns {string}
     */
    static get gameProgress() {
        return localStorage.getItem("game-progress");
    }

    /**
     * @name playerOrder
     * @returns {Array}
     */
    static get playerOrder() {
        return JSON.parse(localStorage.getItem("player-order"));
    }

    static set gameId(val) {
        localStorage.setItem('gameId', val);
    }

    static set roundNumber(val) {
        localStorage.setItem('roundNumber', val);
    }

    static set currentPlayer(val) {
        localStorage.setItem('current-player', val);
    }

    static set tokenDistribution(val) {
        localStorage.setItem('token-distribution', val);
    }

    static set bonusMoveMadeBy(val) {
        localStorage.setItem("bonus-move-made-by", val);
    }

    static set playerOrder(val) {
        localStorage.setItem("player-order", JSON.stringify(val));
    }

    /**
     * starting -> discussion -> distribution -> submittable -> submitted
     */
    static set gameProgress(val) {
        localStorage.setItem("game-progress", val);
    }

    /**
     * Method to get the saved timer progress.
     * @name timerProgress
     * @returns {number} timer progress
     */
    static getTimerProgress(phase) {
        return parseInt(localStorage.getItem(`timer-${phase}`), 10);
    }

    /**
     * Method to set the timer progress.
     * @param {string} phase - distribution or discussion
     * @param {number} val - progress
     */
    static setTimerProgress(phase, val) {
        return localStorage.setItem(`timer-${phase}`, val);
    }

    /**
     * Method to start a new game.
     * @name startNewGame
     * @returns {Object||null} game or null
     */
    static async startNewGame() {
        const game = await getAxiosResult(axios.post('/spel'));
        if (game && game.id) {
            Game.gameProgress = "starting";
            Game.gameId = game.id;
            Game.roundNumber = 1;
            Game.setTimerProgress("discussion", null);
            Game.setTimerProgress("distribution", null);
            Game.playerOrder = null;
            Game.currentPlayer = null;
            Game.tokenDistribution = null;
            Game.bonusMoveMadeBy = null;
            return game;
        }
        return null;
    }

    static async startNewRound() {
        const round = await getAxiosResult(axios.post(`/spel/${Game.gameId}/ronde`));
        if (round && round.nummer) {
            Game.gameProgress = "starting";
            Game.roundNumber = round.nummer;
            Game.setTimerProgress("discussion", null);
            Game.setTimerProgress("distribution", null);
            Game.playerOrder = null;
            Game.currentPlayer = null;
            Game.tokenDistribution = null;
            Game.bonusMoveMadeBy = null;
            return round;
        }
        return null;
    }

    /**
     * Method to fetch players.
     * @name getPlayers
     * @returns {Array<Object>} players
     */
    static async getPlayers() {
        return getAxiosResult(axios.get('/spelers'));
    }

    /**
     * Method to fetch token types.
     * @name getTokenTypes
     * @returns {Array<Object>} tokenTypes
     */
    static async getTokenTypes() {
        return getAxiosResult(axios.get('/tokenTypes'));
    }

    /**
     * Method to fetch game options.
     * @name getOptions
     * @returns {Objetc} options
     */
    static async getOptions() {
        return getAxiosResult(axios.get('/opties'));
    }

    /**
     * Method to fetch island state.
     * @name getIslandState
     * @returns {Object} island state
     */
    static async getIslandState() {
        return getAxiosResult(axios.get(`/spel/${Game.gameId}/eilandStaat`));
    }

    /**
     * Method to get player wallets.
     * @name getPlayers
     * @returns {Object} wallet
     */
    static async getPlayerWallets() {
        return getAxiosResult(axios.get(`/spel/${Game.gameId}/geld`));
    }

    /**
     * Method to get available projects.
     * @name getAvailableProjects
     * @returns {Array<Object>} projects
     */
    static async getAvailableProjects() {
        return getAxiosResult(axios.get(`/spel/${Game.gameId}/beschikbareBouwprojecten`));
    }

    /**
     * Method to get project costs.
     * @name getProjectCosts
     * @param {Array<number>} projectIds
     * @returns {Array<Objects>} costs
     */
    static async getProjectCosts(projectIds = []) {
        return getAxiosResult(axios.get(`/spel/${Game.gameId}/bouwprojectKosten/${projectIds.join(",")}`));
    }

    /**
     * Method to get project yields.
     * @name getProjectYields
     * @param {Array<number>} projectIds
     * @returns {Array<Objects>} yields
     */
    static async getProjectYields(projectIds = []) {
        return getAxiosResult(axios.get(`/spel/${Game.gameId}/bouwprojectOpbrengsten/${projectIds.join(",")}`));
    }

    /**
     * Method to get consequences that happened. (current round only)
     * @name getConsequencesThatHappenedThisRound
     * @returns {Array<Objects>} consequences
     */
    static async getConsequencesThatHappenedThisRound() {
        return getAxiosResult(axios.get(`/spel/${Game.gameId}/gebeurtenissen/${Game.roundNumber}`));
    }

    /**
     * Method to get consequences that happened.
     * @name getConsequencesThatHappened
     * @returns {Array<Objects>} consequences
     */
    static async getConsequencesThatHappened() {
        return getAxiosResult(axios.get(`/spel/${Game.gameId}/gebeurtenissen`));
    }

    /**
     * Method to get all the global consequences that happened. (all)
     * @name getGlobalConsequencesThatHappened
     * @returns {Array<Objects>} consequences
     */
    static async getGlobalConsequencesThatHappened() {
        return getAxiosResult(axios.get(`/spel/${Game.gameId}/eilandGebeurtenissen`));
    }

    /**
     * Method to calculate the spendable wallet.
     * This is done by extracting spended tokens in game state from the players wallet.
     * @param {string} playerId
     * @param {object} wallet 
     * @param {object} tokenDistribution 
     * @returns {object} spendable wallet
     */
    static getSpendableWallet(playerId, wallet, tokenDistribution) {
        const walletClone = { ...wallet };
        Object.keys(tokenDistribution).forEach((projectId) => {
            tokenDistribution[projectId].forEach((cost) => {
                if (playerId === cost.spelerId) {
                    walletClone[cost.tokenTypeId]--;
                }
            });
        });
        return walletClone;
    }

    /**
     * Method to get the player rankings.
     * @name getRankings
     * @returns {Promise}
     */
    static async getRankings() {
        return getAxiosResult(axios.get(`/spel/${Game.gameId}/rankings`));
    }

    /**
     * Method to get the game results at the end of the game.
     * @name getGameResult
     * @returns {Promise}
     */
    static async getGameResult() {
        return getAxiosResult(axios.get(`/spel/${Game.gameId}/result`));
    }

    /**
     * Method to post token distribution on projects.
     * @param {*} distribution 
     * @returns {Promise} result
     */
    static async submitTokenDistribution(distribution) {
        return getAxiosResult(axios.post(`/spel/${Game.gameId}/verstuurTokenVerdeling`, distribution));
    }
}
