import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Token from './Token';
import Popup from './Popup';

import { addPayment } from '../../redux/actions/tokenDistribution';
import Game from '../../classes/Game';

import { playSound } from '../../helpers/audio';
import { __ } from '../../i18n';

const DistributeWallet = (props) => {
    const [holding, setHolding] = useState(null);
    const [pendingPayment, setPendingPayment]  = useState(null);
    const [popup, showPopup] = useState(null);
    const [hovering, setHovering] = useState(null);
    const walletClone = { ...props.spendableWallet };

    useEffect(() => {
        showPopup(null);
        setHolding(null);
        setPendingPayment(null);
        setHovering(null);
        Array.prototype.forEach.call(document.getElementsByClassName('project-cost'), (el) => {
            el.classList.remove('can-make-move');
            el.classList.remove('cant-make-move');
            el.classList.remove("hover");
        });
        Array.prototype.forEach.call(document.getElementsByClassName('token-clone'), (el) => {
            el.remove();
        });
    }, [props.currentPlayer]);

    // Extract holding token
    if (holding) {
        walletClone[holding.type]--;
    }


    const handleDragStop = (ev) => {
        if (holding && hovering) {
            showPopup("confirm-move");
            setPendingPayment({
                projectId: hovering.projectId,
                payment: {
                    spelerId: holding.belongsTo,
                    index: hovering.costIndex,
                    tokenTypeId: holding.type,
                },
            });
            setHovering(null);
            setHolding(null);
        }
    };

    const handleCommitPayment = (claimedBonus = false) => {
        if (pendingPayment) {
            const project = props.projects.find(p => p.id === pendingPayment.projectId);
            if (project) {

                // Determine wether we use the bonus turn and play another token or not
                let soundToPlay = null;
                if (!claimedBonus || Game.bonusMoveMadeBy) {
                    soundToPlay = "token-placed";
                    props.nextPlayer();
                } else {
                    soundToPlay = "bonus-move-made";
                    Game.bonusMoveMadeBy = pendingPayment.payment.spelerId;
                }

                // Check if this move completes the project, if so, play project completed sound instead
                const tokensProvided = [pendingPayment.payment].concat(props.tokenDistribution.distribution[project.id]);
                const projectCompleted = project.costs.every((cost, i) => {
                    return tokensProvided.find(c => c.index === i);
                });
                if (projectCompleted) {
                    soundToPlay = "project-completed";
                }

                if (soundToPlay) {
                    playSound(soundToPlay);
                }

                // Finalize payment by adding it to the store
                props.dispatch(addPayment(pendingPayment.payment, pendingPayment.projectId));
            }
        }
        showPopup(null);
    };

    return (
        <div className="controls-container distribute-controls-wallet">
            <div className="controls-container__left-top" />
            <div className="tokens-container">
                <Token
                    type="K"
                    amount={walletClone.K}
                    inactive={!walletClone.K}
                    draggable={walletClone.K > 0 || (holding && holding.type === "K")}
                    belongsTo={props.currentPlayer}
                    setHolding={setHolding}
                    setHovering={setHovering}
                    holding={holding && holding.type === "K" ? holding : null}
                    hovering={hovering}
                    handleDragStop={handleDragStop}
                />
                <Token
                    type="$"
                    amount={walletClone.$}
                    inactive={!walletClone.$}
                    draggable={walletClone.$ > 0 || (holding && holding.type === "$")}
                    belongsTo={props.currentPlayer}
                    setHolding={setHolding}
                    setHovering={setHovering}
                    holding={holding && holding.type === "$" ? holding : null}
                    hovering={hovering}
                    handleDragStop={handleDragStop}
                />
                <Token
                    type="A"
                    amount={walletClone.A}
                    inactive={!walletClone.A}
                    draggable={walletClone.A > 0 || (holding && holding.type === "A")}
                    belongsTo={props.currentPlayer}
                    setHolding={setHolding}
                    setHovering={setHovering}
                    holding={holding && holding.type === "A" ? holding : null}
                    hovering={hovering}
                    handleDragStop={handleDragStop}
                />
            </div>
            <div className="controls-container__right-top" />
            <Popup show={popup === 'confirm-move'} name={popup} handleClose={() => showPopup(null)}>
                <div className="button-controls">
                    <Token label={__("confirm_move_button_label")} type="OK" onClick={() => handleCommitPayment(false)} />
                    <Token label={__("decline_move_button_label")} type="NOK" onClick={() => showPopup(null)} />
                    {!Game.bonusMoveMadeBy ? <Token label={__("claim_bonus_button_label")} type="BONUS" onClick={() => handleCommitPayment(true)} /> : null}
                </div>
            </Popup>
        </div>
    );
};

DistributeWallet.propTypes = {
    spendableWallet: PropTypes.object,
    currentPlayer: PropTypes.string,
    nextPlayer: PropTypes.func,
};
DistributeWallet.defaultProps = {
    nextPlayer: () => {},
};

export default connect(({ players, projects, tokenDistribution }) => ({ players, projects, tokenDistribution }))(DistributeWallet);
