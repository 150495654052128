const progressToRouteMap = {
    "starting": "/eiland",
    "discussion": "/bespreken-en-inleggen",
    "distribution": "/bespreken-en-inleggen",
    "submittable": "/eiland",
    "submitted": "/eiland",
};

/**
 * Method to get the appropriate route path for the current game progress.
 * @param {string} gameProgress - starting||discussion||distribution||submitted
 */
const getAppropriateRoutePath = (gameProgress) => {
    return progressToRouteMap[gameProgress];
};

export default getAppropriateRoutePath;
