import {
    UPDATE_PLAYERS,
    SHUFFLE_PLAYERS,
} from '../types';

export const updatePlayers = (payload) => (dispatch) => {
    dispatch({ type: UPDATE_PLAYERS, payload });
};

export const shufflePlayers = () => (dispatch) => {
    dispatch({ type: SHUFFLE_PLAYERS });
};

export default {
    updatePlayers,
};
  