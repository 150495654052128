import { UPDATE_ISLAND_STATE } from '../types';

const initialState = [];

const island = (state = initialState, action) => {
    const { payload, type } = action;

    switch(type) {
        case UPDATE_ISLAND_STATE:
            return [...payload];
        default:
            return state;
    }
};

export default island;
