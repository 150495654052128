import { UPDATE_CONSTANTS } from '../types';

const initialState = {
    options: {},
    tokenTypes: [],
};

const constants = (state = initialState, action) => {
    const { payload, type } = action;

    switch(type) {
        case UPDATE_CONSTANTS:
            return {
                ...state,
                ...payload,
            }
        default:
            return state;
    }
};

export default constants;
