import React from 'react';
import LineChartRow from './LineChartRow';
import Game from '../../classes/Game';

import { __ } from '../../i18n';


const LineChart = (props) => {
    const levels = [...Array(props.levels).keys()];
    return (
        <div id="line-chart" className="line-chart">
            <div className="line-chart__inner">
                <div className="line-chart__rulers">
                    <div className="line-chart__rulers__ruler" />
                    {levels.map(l => <div key={l} style={{ left: `${((l + 1) * (100 / levels.length))}%` }} className="line-chart__rulers__ruler" />)}
                </div>
                <div className="line-chart__top">
                    {levels.map((i) => {
                        return (
                            <div key={i} className="line-chart__top__col">
                                <h2 className={(i + 1) === Game.roundNumber ? "active" : ""}>{(i + 1) === Game.roundNumber ? __("level_uppercase") + " " : ""}{i + 1}</h2>
                            </div>
                        );
                    })}
                </div>
                <div className="line-chart__rows">
                    {props.dataSets.map((dataSet, i) => {
                        const zIndex = props.dataSets.length - i;
                        return (
                            <LineChartRow
                                key={i}
                                levels={props.levels}
                                zIndex={zIndex}
                                {...dataSet}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default LineChart;
