import React, { useState, useEffect } from 'react';
import LoadingScene from '../components/scenes/LoadingScene';
import config from '../config';

/**
 * Method to apply middleware to a component.
 * @param {*} component 
 * @param {*} middleware 
 */
export default function(Component, props, middleware = []) {
    const [toRender, setToRender] = useState(null);
    useEffect(() => {
        new Promise(async (resolve, reject) => {
            let c = <Component {...props} />;
            try {
                if (config.logging) {
                    console.group(`Middleware for route: ${props.history.location.pathname}`);
                }
                for(const m of middleware) {
                    if (config.logging) {
                        console.log(`Using ${m.name}...`);
                    }
                    c = await m(Component, c.props);
                }
                if (config.logging) {
                    console.groupEnd();
                }
                resolve(c);
            } catch(err) {
                reject(err);
            }
        }).then((c) => {
            setToRender(c);
        }).catch((err) => {
            console.error(err);
        });
    }, []);

    if (!toRender) {
        return <LoadingScene />;
    }
    return toRender;
}