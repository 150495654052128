import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { __, getLocale } from '../../i18n';
import Game from '../../classes/Game';
import { setReadyForSubmit } from '../../redux/actions/tokenDistribution';
import ProjectCard from '../elements/ProjectCard';
import DiscussControls from '../elements/DiscussControls';
import DistributeControls from '../elements/DistributeControls';
import DistributeWallet from '../elements/DistributeWallet';
import PlayersSlider from '../elements/PlayersSlider';
import PlayerCardStack from '../elements/PlayerCardStack';
import Token from '../elements/Token';
import Popup from '../elements/Popup';

import { playSound, stopSound } from '../../helpers/audio';

const DiscussAndDistributeScene = (props) => {
    const [phase, setPhase] = useState(Game.gameProgress);
    const [currentPlayer, setCurrentPlayer] = useState(Game.currentPlayer || props.players[0].id);
    const [popup, showPopup] = useState(null);
    const [closedDiscussExplanation, setClosedDiscussExplanation] = useState(false);
    const [closedDistributeExplanation, setClosedDistributionExplanation] = useState(false);
    const player = { ...props.players.find(p => p.id === currentPlayer) };
    player.spendableWallet = Game.getSpendableWallet(player.id, player.wallet, props.tokenDistribution.distribution);

    useEffect(() => {
        showPopup(`${phase}-explanation`);
        return () => {
            // Ensure no clones are left behind
            Array.prototype.forEach.call(document.getElementsByClassName('token-clone'), (el) => {
                el.remove();
            });
        };
    }, [phase]);

    const nextPlayer = () => {
        const currentPlayerIndex = props.players.findIndex(p => p.id === currentPlayer);
        const newCurrentPlayer = props.players[currentPlayerIndex + 1];
        if (typeof newCurrentPlayer === 'object') {
            playSound("card-swap");
            Game.currentPlayer = newCurrentPlayer.id;
            Game.setTimerProgress(phase, 0);
            setCurrentPlayer(newCurrentPlayer.id);
        } else {
            // Ensure timer ending sound is stopped
            if (window.timerEndingSound) {
                stopSound("timer-ending", window.timerEndingSound);
                window.timerEndingSound = null;
            }
            props.dispatch(setReadyForSubmit(true));
            Game.gameProgress = "submittable";
            props.history.push("/eiland");
        }
    };

    return (
        <div className="scene discuss-and-distribute-scene">

            <div className={`projects-container ${phase}`}>
                {props.projects.map((project, i) => {
                    return (
                        <ProjectCard
                            id={project.id}
                            phase={phase}
                            key={project.id + "-card"}
                            title={getLocale() === 'en' ? project.naamEN : project.naam}
                            costs={project.costs}
                            yields={project.yields}
                            description={getLocale() === 'en' ? project.omschrijvingEN : project.omschrijving}
                            number={i + 1}
                            style={{
                                animationDelay: (i * 50) + 'ms'
                            }}
                            currentPlayer={player}
                        />
                    );
                })}
                {phase === 'discussion' ? <DiscussControls closedDiscussExplanation={closedDiscussExplanation} onDiscussionEnded={() => { Game.gameProgress = "distribution"; setPhase("distribution"); }} /> : ""}
                {phase === 'distribution' ? (
                    <React.Fragment>
                        <DistributeWallet currentPlayer={player.id} spendableWallet={player.spendableWallet} nextPlayer={nextPlayer} />
                        <DistributeControls currentPlayer={player.id} onDistributeEnded={nextPlayer} closedDistributeExplanation={closedDistributeExplanation} />
                    </React.Fragment>
                ) : ""}
            </div>

            {phase === 'discussion' ? (
                <div className="discuss">
                    <div className="discuss-container">
                        <div className="discuss__players-container">
                            <PlayersSlider animateEffects={closedDiscussExplanation} />
                        </div>
                    </div>

                    {/* Discussion phase explanation popup */}
                    <Popup show={popup === 'discussion-explanation'} name={popup} handleClose={() => {showPopup(null); setClosedDiscussExplanation(true); }}>
                        <div className="title-container">
                            <h2>{__("level_uppercase")} 0{Game.roundNumber}</h2>
                        </div>
                        <div className="content">
                            <ReactMarkdown children={__('discussion_explanation_popup_text')} />
                        </div>
                        <div className="button-controls">
                            <Token label={__("close_button_label")} type="NOK" onClick={() => {showPopup(null); setClosedDiscussExplanation(true); }} />
                        </div>
                    </Popup>
                </div>
            ) : null}

            {phase === 'distribution' ? (
                <div className="distribute">
                    <div className="distribute__left-container">
                        <h2>{__("level_uppercase")} 0{Game.roundNumber}</h2>
                        <PlayerCardStack
                            players={props.players}
                            currentPlayer={currentPlayer}
                        />
                    </div>
                    {Game.bonusMoveMadeBy ? (
                        <div className="bonus-move-available">
                            <p dangerouslySetInnerHTML={{ __html: __('bonus_move_unavailable') }} />
                            <Token type="BONUS" inactive />
                        </div>
                    ) : (
                        <div className="bonus-move-available">
                            <p dangerouslySetInnerHTML={{ __html: __('bonus_move_available') }} />
                            <Token type="BONUS" />
                        </div>
                    )}

                    {/* Distribution phase explanation popup */}
                    <Popup show={popup === 'distribution-explanation'} name={popup} handleClose={() => { showPopup(null); setClosedDistributionExplanation(true); }}>
                        <div className="title-container">
                            <h2>{__('level_uppercase')} 0{Game.roundNumber}</h2>
                        </div>
                        <div className="content">
                            <ReactMarkdown children={__("distribution_explanation_popup_text")} />
                        </div>
                        <div className="button-controls">
                            <Token label={__("close_button_label")} type="NOK" onClick={() => { showPopup(null); setClosedDistributionExplanation(true); }} />
                        </div>
                    </Popup>
                </div>
            ) : null}
        </div>
    );
};

export default connect((state) => ({ ...state }))(DiscussAndDistributeScene);
