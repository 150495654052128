import React, { useEffect } from 'react';
import assets from '../../../helpers/assets';
import { playSound } from '../../../helpers/audio';

const spritesOverrides = [
    ["VAR", ["VARBASIS"]],
    ["VEG", ["VEGBASIS"]],
    ["KOL1", ["KOLBASIS"]],
    ["KOL2", ["KOLBASIS", "KOL1"]],
    ["KOL6", ["KOLBASIS", "KOL1", "KOL2"]],
    ["KOL5", ["KOLBASIS", "KOL1", "KOL2", "KOL6"]],
    ["WKR2", ["WKR1"]],
    ["GAS2", ["GAS1"]],
];

const Island = (props) => {
    let timeouts = [];

    useEffect(() => {
        return () => timeouts.map(t => clearTimeout(t));
    });
    useEffect(() => () => {}, [props.refresh]);

    // Determine water level
    let waterLevel = 1;
    if (props.state.zee >= 2 && props.state.zee < 4) {
        waterLevel = 2;
    }
    if (props.state.zee >= 4 && props.state.zee < 6) {
        waterLevel = 3;
    }
    if (props.state.zee >= 6 && props.state.zee < 8) {
        waterLevel = 4;
    } 
    if (props.state.zee >= 8) {
        waterLevel = 5;
    }

    // Sprites to turn on
    let enabledSprites = ["KOLBASIS", "VARBASIS", "VEGBASIS", ...props.projects].filter(s => assets[`islandSprite${s}`]) // filter out sprites that dont exist in assets;

    // Filter out overrided sprites
    spritesOverrides.forEach((override) => {
        const sprite = override[0];
        const overwrites = override[1];

        // Sprite is enabled
        if (enabledSprites.find(es => es === sprite)) {
            // Remove sprites that this sprite overwrites
            overwrites.forEach((spriteToOverwrite) => {
                const indexToRemove = enabledSprites.findIndex(es => es === spriteToOverwrite);
                if (indexToRemove !== -1) {
                    enabledSprites[indexToRemove] = null;
                }
            });
        }
    });
    enabledSprites = enabledSprites.filter(s => s !== null);
    let newI = 0;
    return (
        <div className="island">
            <img className="island__background" src={assets[`islandBackgroundLevel${waterLevel}`]} alt="island-background" />

            {/* Island sprites */}
            {enabledSprites.map((sprite, i) => {
                const buildThisRound = props.newProjects.includes(sprite);
                if (buildThisRound) newI++;
                return (
                    <img
                        className={`island-sprite ${buildThisRound ? " new" : ""}`}
                        key={`island-sprite-${i}`}
                        src={assets[`islandSprite${sprite}${buildThisRound ? "NEW" : ""}`] || assets[`islandSprite${sprite}`]}
                        alt={`island sprite ${sprite}`}
                        style={{ zIndex: i }}
                        data-newIndex={newI}
                        ref={(spriteEl) => {
                            if (buildThisRound && spriteEl) {
                                timeouts.push(setTimeout(() => {
                                    if (spriteEl) {
                                        playSound("new-sprite");
                                        spriteEl.classList.add("fadeIn");
                                    }
                                }, parseInt(spriteEl.getAttribute("data-newindex"), 10) * 500));
                            }
                        }}
                    />
                );
            })}

            {/* Island sprites from global consequences */}
            {props.globalConsequences.map((globalConsequence, i) => {
                const source = assets[`islandSpriteGlobalConsequence${globalConsequence}`];
                if (!source) return null;
                return (
                    <img
                        className={`island-sprite global-consequence`}
                        key={`global-consequence-sprite-${i}`}
                        src={source}
                        alt={`global consequence sprite ${globalConsequence}`}
                        style={{ zIndex: enabledSprites.length + i }}
                    />
                );
            })}
        </div>
    );
};

Island.defaultProps = {
    state: {},
    projects: [],
    newProjects: [],
    globalConsequences: [], // Global consequences (ids) that happened this round and should be previewed.
};

export default React.memo(Island, (prevProps, nextProps) => {
    return prevProps.refresh === nextProps.refresh;
});
