import axios from 'axios';
import config from '../config';

const axiosInstance = axios.create({
    baseURL: config.apiUrl,
});

axiosInstance.interceptors.request.use((opts) => {
    opts.headers.SERVER_SECRET = config.serverSecret;
    return opts;
});

export default axiosInstance;
