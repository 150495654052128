import React, { useEffect, useState } from 'react';
import Game from '../../classes/Game';
import Token from './Token';
import Loader from './Loader';

import { __, __n } from '../../i18n';

const PlayerRanking = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [rankings, setRankings] = useState([]);
    const className = `player-ranking${props.className ? ` ${props.className}` : ''}`;

    useEffect(() => {
        if (loaded) {
            setRankings([]);
        }
        Game.getRankings().then((result) => {
            setRankings(result);
            setLoaded(true);
        });
    }, [props.refresh]);

    return (
        <div id="player-ranking" className={className}>
            <div className="player-ranking__title-container">
                <h3>{__("player_ranking_title")}</h3>
                <span />
            </div>
            {loaded ? (
                <div className="player-ranking__rankings">
                    {rankings.map((ranking, i) => {
                        return (
                            <div key={ranking.id} style={{ animationDelay: `${i * 50}ms` }} className={`player-ranking__rankings__rank${i === 0 && ranking.punten > 0 ? ' winner' : ''}`}>
                                <Token
                                    type={ranking.id}
                                />
                                <p className="player-ranking__rankings__rank__label">{ranking.punten} {__n("points", ranking.punten)}</p>
                            </div>
                        );
                    })}
                </div>
            ) : <Loader theme="light" />}
        </div>
    );
};

PlayerRanking.propTypes = {};
PlayerRanking.defaultProps = {};

export default PlayerRanking;
