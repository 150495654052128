import React from 'react';
import Game from '../../classes/Game';
import { updateConstants } from '../../redux/actions/constants';
import { updatePlayers } from '../../redux/actions/players';
import { updateProjects } from '../../redux/actions/projects';
import { updateIslandState } from '../../redux/actions/island';
import { updateConsequences, updateGlobalConsequences } from '../../redux/actions/consequences';
import config from '../../config';

const DataLoader = async (Component, props) => {

    const reloadGameData = async (store) => {
        if (config.logging) {
            console.log("Reloading game data");
        }
        // Fetch data
        const [
            players,
            island,
            tokenTypes,
            options,
            playerWallets,
            availableProjects,
            consequences,
            globalConsequences,
        ] = await Promise.all([
            Game.getPlayers(),
            Game.getIslandState(),
            Game.getTokenTypes(),
            Game.getOptions(),
            Game.getPlayerWallets(),
            Game.getAvailableProjects(),
            Game.getConsequencesThatHappened(),
            Game.getGlobalConsequencesThatHappened(),
        ]);
        const availableProjectIds = availableProjects.map((p) => p.id);
        const projectCosts = await Game.getProjectCosts(availableProjectIds);
        const projectYields = await Game.getProjectYields(availableProjectIds);

        // Update options in store
        store.dispatch(updateConstants({
            options,
            tokenTypes,
        }));

        // Update players in store
        store.dispatch(updatePlayers(players.map((player) => {
            return {
                ...player,
                wallet: playerWallets[player.id] || {},
            };
        })));

        // Update projects in store
        store.dispatch(updateProjects(availableProjects.map((project) => {
            return {
                ...project,
                costs: projectCosts[project.id] || [],
                yields: projectYields[project.id] || [],
            };
        })));

        // Update island in store
        store.dispatch(updateIslandState(island));

        // Update consequences in store
        store.dispatch(updateConsequences(consequences));
        store.dispatch(updateGlobalConsequences(globalConsequences));
    }

    await reloadGameData(props.store);
    
    return <Component {...props} reloadGameData={reloadGameData} />;
};

export default DataLoader;
