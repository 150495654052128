import React from 'react';
import { __ } from '../../i18n';

import Token from '../elements/Token';
import PlayersSlider from '../elements/PlayersSlider';

const PlayerIntroductionScene = (props) => {
    return (
        <div className="scene player-introduction">
            <div className="player-introduction__header">
                <div className="player-introduction__header__inner">

                    {/* Title */}
                    <h1>{__("the_inhabitants")}</h1>

                    {/* Controls */}
                    <div className="controls-container">
                        <div className="controls-container__left-top" />
                        <Token
                            type="NOK"
                            attachment={__("rewatch_intro_video_button_label")}
                            onClick={() => props.history.push('/introductie')}
                        />
                        <div style={{ width: '10px' }} />
                        <Token
                            type="OK"
                            attachment={__("start_game_button_label")}
                            onClick={() => props.history.push('/eiland')}
                            pulse
                        />
                        <div className="controls-container__right-top" />
                    </div>
                </div>
            </div>
            <div className="player-introduction__content-container">

                {/* Players */}
                <PlayersSlider introduction />

                {/* Instruction text */}
                <p className="player-introduction__content-container__explanation">{__("player_introduction_explanation")}</p>

            </div>
        </div>
    );
};

export default PlayerIntroductionScene;
