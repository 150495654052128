import React from "react";
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import CustomScroll from 'react-custom-scroll';

import Game from '../../classes/Game';

import { __, getLocale } from '../../i18n';

const FeedbackDashboard = (props) => {
    const readyForNewRound = Game.gameProgress === "submitted" && Game.roundNumber < props.constants.options.aantal_rondes_in_spel;
    const gameEnded = Game.gameProgress === "submitted" && Game.roundNumber === props.constants.options.aantal_rondes_in_spel;

    const projectConsequences = props.consequences.consequencesThisRound.filter(c => (getLocale() === 'en' ? c.opmerkingEN : c.opmerking));
    const globalConsequences = props.consequences.globalConsequences.filter(c => (getLocale() === 'en' ? c.opmerkingEN : c.opmerking)).filter(c => {
        // If ronde is set, only show feedback for consequences from current round
        if (!c.ronde) return true;
        return c.ronde === Game.roundNumber;
    });

    return (
        <div id="feedback-dashboard" className="feedback-dashboard">
            <div className="feedback-dashboard__title-container">
                <h3>{__("feedback_dashboard_title")}</h3>
                <span />
            </div>
            <div className="feedback-dashboard__inner">
                <CustomScroll allowOuterScroll={true}>
                    <div className="feedback-dashboard__inner__container">
                        {props.tokenDistribution.readyForSubmit && !readyForNewRound && !gameEnded ? (
                            <div className="explanation">
                                <ReactMarkdown children={__("feedback_dashboard_explanation__before_show_results")} />
                            </div>
                        ) : null}
                        {!props.tokenDistribution.readyForSubmit && !readyForNewRound && !gameEnded ? (
                            <div className="explanation">
                                <ReactMarkdown children={__("feedback_dashboard_explanation__before_start_game")} />
                            </div>
                        ) : null}
                        {(readyForNewRound || gameEnded) && projectConsequences !== null && globalConsequences !== null ? (
                            !projectConsequences.length && !globalConsequences.length ? (
                                <p>{__("feedback_dashboard_no_feedback")}</p>
                            ) : (
                                <React.Fragment>
                                    <div className="feedback-dashboard__general">
                                        {globalConsequences.map((consequence) => {
                                            return (
                                                <div key={consequence.id}>
                                                    <ReactMarkdown children={getLocale() === 'en' ? consequence.opmerkingEN : consequence.opmerking} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="feedback-dashboard__other">
                                        {projectConsequences.map((consequence) => {
                                            return (
                                                <div key={consequence.id}>
                                                    <ReactMarkdown children={getLocale() === 'en' ? consequence.opmerkingEN : consequence.opmerking} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </React.Fragment>
                            )
                        ) : null}
                    </div>
                </CustomScroll>
            </div>
        </div>
    );
};

export default connect(({ constants, tokenDistribution, consequences }) => ({ constants, tokenDistribution, consequences }))(FeedbackDashboard);
