import React, { useState, useEffect } from 'react';
import Popup from './Popup';
// import Token from './Token';
import Loader from './Loader';
import Game from '../../classes/Game';
import { __ } from '../../i18n';

const GameResult = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [gameResult, setGameResult] = useState(null);
    const [rankings, setRankings] = useState(null);
    const [showingGameEndedPopup, showGameEndedPopup] = useState(true);

    useEffect(() => {
        if (loaded) {
            setGameResult(null);
        }
        Promise.all([
            Game.getGameResult(),
            Game.getRankings(),
        ]).then(([gr, r]) => {
            setGameResult(gr);
            setRankings(r);
            setLoaded(true);
        });
    }, []);
    if (!loaded || !gameResult) return null;
    return (
        <Popup show={showingGameEndedPopup} name="end-result" handleClose={() => {}}>
            <React.Fragment>
                <div className="description-container">
                    <h2>{__("Het eindresultaat")}</h2>
                    <ul>
                        <li><strong>{__("CO2_neutral")}:</strong> {__(gameResult.co2Neutral ? "Ja" : "Nee")}</li>
                        <li>{__("Steeds voldoende")} <strong>{__("energie")}</strong>: {__(gameResult.noEnergyShortage ? "Ja" : "Nee")}</li>
                        <li>100% <strong>{__("groene energie")}</strong>: {__(gameResult.onlyGreenEnergy ? "Ja" : "Nee")}</li>
                    </ul>
                    <p style={{ marginBottom: '20px' }}>{gameResult.gameWon ? __("mission_success") : __("mission_failed")}</p>
                    <p>{__("Energy Master(s)")}: {rankings.filter(r => r.punten === rankings[0].punten).map(r => r.naam).join(", ")}</p>
                </div>
                {/* <div className="button-controls">
                    <Token label={__("close_button_label")} type="NOK" onClick={() => showGameEndedPopup(false)} />
                </div> */}
            </React.Fragment>
        </Popup>
    );
};

export default GameResult;
