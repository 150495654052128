
/**
 * Method to get the result from an axios call.
 * @param {Promise} axiosCall
 * @returns {Promise} result
 */
const getAxiosResult = (axiosCall) => {
    return axiosCall.then((result) => {
        if (result.status === 200) {
            return result.data ? result.data : true;
        }
        return false;
    })
    .catch((err) => {
        console.error(err);
        return null;
    });
};

export default getAxiosResult;
