import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import leftPad from 'left-pad';
import Game from '../../classes/Game';
import { __ } from '../../i18n';

import { playSound, stopSound } from '../../helpers/audio';

function millisToMinutesAndSeconds(millis) {
    var minutes = parseInt(Math.floor(millis / 60000), 10);
    var seconds = parseInt(((millis % 60000) / 1000).toFixed(0), 10);
    if (seconds === 60) {
        seconds = 59;
    }
    if (minutes < 0) {
        minutes = 0;
    }
    if (seconds < 0) {
        seconds = 0;
    }
    return leftPad(minutes, 2, 0) + ":" + leftPad((seconds < 10 ? '0' : '') + seconds, 2, 0);
}

window.timerEndingSound = null;

const Timer = (props) => {
    const savedTime = Game.getTimerProgress(props.phase);
    const timerPhaseRef = useRef(null);
    const timerCountdownRef = useRef(null);
    const timerPointerRef = useRef(null);
    let start = null;
    let raf = null;
    let progress = null;
    let lastProgress = 0;

    /**
     * Animation step
     */
    const step = (timestamp) => {
        const once = props.paused;
        if (!start) start = timestamp;
        if (savedTime) {
            timestamp += savedTime;
        }
        progress = timestamp - start;
        const remaining = Math.abs(props.duration - progress);
        const progressPercentage = (100 / props.duration) * progress;

        if (progress - lastProgress >= 200) { // Save progress every second
            Game.setTimerProgress(props.phase, progress);
            lastProgress = progress;
        }

        if (timerCountdownRef && timerCountdownRef.current) {
            timerCountdownRef.current.innerText = millisToMinutesAndSeconds(remaining);

            // If theres a cloned timer element, also update this one
            const timerClone = document.querySelector('.timer.clone');
            if (timerClone && timerClone.querySelector('.timer__attachment__countdown')) {
                timerClone.querySelector('.timer__attachment__countdown').innerText = timerCountdownRef.current.innerText;
            }
        }
        if (timerPointerRef && timerPointerRef.current) {
            timerPointerRef.current.style.transform = `translateX(-50%) rotateZ(${(360 / 100) * progressPercentage}deg)`;

            // If theres a cloned timer element, also update this one
            const timerClone = document.querySelector('.timer.clone');
            if (timerClone && timerClone.querySelector('.timer__indicator__pointer.moving')) {
                timerClone.querySelector('.timer__indicator__pointer.moving').style.transform = timerPointerRef.current.style.transform;
            }
        }

        // 10 seconds left
        if (props.duration - progress <= (10 * 1000)) {
            if (!window.timerEndingSound && !props.paused) {
                window.timerEndingSound = playSound("timer-ending");
            }
            if (window.timerEndingSound && props.paused) {
                stopSound("timer-ending", window.timerEndingSound);
                window.timerEndingSound = null;
            }
        } else {
            if (window.timerEndingSound) {
                stopSound("timer-ending", window.timerEndingSound);
                window.timerEndingSound = null;
            }
        }
        
        if (progress >= props.duration) {
            Game.setTimerProgress(props.phase, props.duration);
            props.onEnd();
        } else {
            if (!once) {
                raf = window.requestAnimationFrame(step);
            }
        }
    };

    useEffect(() => {
        raf = window.requestAnimationFrame(step);
        return () => {
            if (raf) {
                window.cancelAnimationFrame(raf);
            }
        };
    });

    const className = `timer${props.className ? ` ${props.className}` : ''} timer__${props.phase}`;
    return (
        <div className={className} style={props.style} onClick={props.onClick}>
            <div className="timer__inner-circle" />
            <div className="timer__indicator">
                <div className="timer__indicator__pointer" />
                <div ref={timerPointerRef} className="timer__indicator__pointer moving" />
            </div>
            <div className="timer__attachment">
                <span ref={timerPhaseRef} className="timer__attachment__phase">
                    {props.phase === 'discussion' ? __("timer_discussion_time_label") : __("timer_distribution_time_label")}
                </span>
                <span ref={timerCountdownRef} className="timer__attachment__countdown">
                    00:00
                </span>
            </div>
        </div>
    );
};

Timer.propTypes = {
    phase: PropTypes.oneOf(["discussion", "distribution"]),
    onEnd: PropTypes.func,
    duration: PropTypes.number,
    style: PropTypes.object,
    paused: PropTypes.bool,
    onClick: PropTypes.func,
};
Timer.defaultProps = {
    style: {},
    paused: false,
    onClick: () => {},
};

export default Timer;
