import ReactDOM from 'react-dom';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import BendLine from '../../assets/images/png/bendline.png';

const Popup = (props) => {
    const popupRef = useRef(null);
    useEffect(() => {
        if (props.show && popupRef && popupRef.current && !popupRef.current.querySelector('.timer')) {
            const timerElement = document.querySelector('.controls-container .timer');
            if (timerElement) {
                const timerElementClone = timerElement.cloneNode(true);
                timerElementClone.classList.add("clone");
                popupRef.current.appendChild(timerElementClone);
            }
        }
    });

    if (props.show === false) return null;
    const className = `popup${props.className ? ` ${props.className}` : ''} popup__${props.name}`;
    const portal = document.getElementById('popup-portal');

    return ReactDOM.createPortal((
        <div ref={popupRef} className={className}>
            <div className="popup__container">
                <div className="popup__container__corner-circle top-left" />
                <div className="popup__container__corner-circle top-right" />
                <div className="popup__container__outer">
                    <div className="popup__container__outer__circle-line top-left">
                        <img src={BendLine} alt="bendline" />
                    </div>
                    <div className="popup__container__outer__circle-line top-right">
                        <img src={BendLine} alt="bendline" />
                    </div>
                    <div className="popup__container__inner">
                        <div className="popup__container__inner__border-symbol top" />
                        <div className="popup__container__inner__border-symbol right" />
                        <div className="popup__container__inner__border-symbol left" />
                        <div className="popup__container__inner__border-symbol bottom" />
                        <div className="popup__container__inner__content">
                            {props.children}
                        </div>
                    </div>
                    <div className="popup__container__outer__circle-line bottom-left">
                        <img src={BendLine} alt="bendline" />
                    </div>
                    <div className="popup__container__outer__circle-line bottom-right">
                        <img src={BendLine} alt="bendline" />
                    </div>
                </div>
                <div className="popup__container__corner-circle bottom-left" />
                <div className="popup__container__corner-circle bottom-right" />
            </div>
        </div>
    ), portal);
};

Popup.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    name: PropTypes.string,
    show: PropTypes.bool,
};
Popup.defaultProps = {
    className: '',
    children: null,
    name: 'default',
    show: null,
};

export default Popup;
