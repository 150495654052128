import React from 'react';
import { __ } from '../../i18n';

const NotFoundScene = (props) => {

    const goBack = (ev) => {
        props.history.push('/eiland');
        ev.preventDefault();
        ev.stopPropagation();
    };

    return (
        <div className="scene not-found-scene">
            <div className="not-found-scene__inner">
                <h1>{__("not_found_title")}</h1>
                <p>{__("page_not_found_text")} {__("Click")} <a href="/" onClick={goBack}>{__("here")}</a> {__("to_return_to_the_game")}</p>
            </div>
        </div>
    );
};

export default NotFoundScene;
