import React from "react";
import { connect } from "react-redux";
import LineChart from './LineChart';
import PlayerRanking from './PlayerRanking';
import FeedbackDashboard from './FeedbackDashboard';

import { __ } from '../../i18n';

const ProgressDashboard = (props) => {
    const currentRound = props.island[props.island.length - 1];
    const dataSets = [
        {
            label: __("line_graph_co2_label"),
            max: 10,
            type: 'co2',
            data: props.island.reduce((acc, round) => {
                const vals = [];

                // Start
                if (round.nummer === 1) {
                    vals.push(round.startIslandState.co2);
                }

                // End of round
                if (round.endIslandState) {
                    vals.push(round.endIslandState.co2);
                    if (currentRound.id === round.id) {
                        vals.push(round.endIslandState.co2Prognose);
                    }
                } else {
                    if (currentRound.id === round.id) {
                        vals.push(round.startIslandState.co2Prognose);
                    }
                }
                return acc.concat(vals);
            }, []),
        },
        {
            label: __("line_graph_energy_label"),
            type: 'energie',
            max: 150,
            data: props.island.reduce((acc, round) => {
                const startEnergie = props.island[0].startIslandState.energie;
                const vals = [];

                // Start
                if (round.nummer === 1) {
                    vals.push((100 / startEnergie) * parseInt(round.startIslandState.energie, 10));
                }

                // End of round
                if (round.endIslandState) {
                    vals.push((100 / startEnergie) * parseInt(round.endIslandState.energie, 10));
                    if (currentRound.id === round.id) {
                        vals.push((100 / startEnergie) * parseInt(round.endIslandState.energiePrognose, 10));
                    }
                } else {
                    if (currentRound.id === round.id) {
                        vals.push((100 / startEnergie) * parseInt(round.startIslandState.energiePrognose, 10));
                    }
                }

                return acc.concat(vals);
            }, []),
            preLabelsSuffix: '%',
        },
        {
            label: __("line_graph_sea_label"),
            max: 10,
            type: 'zee',
            data: props.island.reduce((acc, round) => {
                const vals = [];
                
                // Start
                if (round.nummer === 1) {
                    vals.push(round.startIslandState.zee);
                }

                // End of round
                if (round.endIslandState) {
                    vals.push(round.endIslandState.zee);
                    if (currentRound.id === round.id) {
                        vals.push(round.endIslandState.zeePrognose);
                    }
                } else {
                    if (currentRound.id === round.id) {
                        vals.push(round.startIslandState.zeePrognose);
                    }
                }

                return acc.concat(vals);
            }, []),
            hidePreLabels: false,
        },
    ];

    // Ensure ok-ish max values
    dataSets.forEach((ds) => {
        const dataMax = Math.max(...ds.data);
        if (dataMax > ds.max) {
            ds.max = dataMax;
        }
    });
    // Update label for energy production
    const currentRoundIslandState = currentRound.endIslandState || currentRound.startIslandState;
    const greenEnergyPercentage = Math.round((100 / currentRoundIslandState.energie) * currentRoundIslandState.groen);
    dataSets[1].label = dataSets[1].label.replace("%", `${greenEnergyPercentage}%`);
    return (
        <div className="progress-dashboard">
            <div className="progress-dashboard__results">
                <LineChart
                    levels={props.constants.options.aantal_rondes_in_spel}
                    dataSets={dataSets}
                />
                <FeedbackDashboard refresh={props.refresh} />
                <PlayerRanking refresh={props.refresh} />
            </div>
        </div>
    );
};

export default connect(({ island, constants }) => ({ island, constants }))(ProgressDashboard);
