import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { clearTokenDistribution } from '../../redux/actions/tokenDistribution';
import { shufflePlayers } from '../../redux/actions/players';
import Game from '../../classes/Game';
import Token from '../elements/Token';
import ProgressDashboard from '../elements/ProgressDashboard';
import GameResult from '../elements/GameResult';
import Island from '../elements/Island';
import LoadingOverlay from '../elements/LoadingOverlay';
import assets from '../../helpers/assets';
import { __ } from '../../i18n';
import { playSound } from '../../helpers/audio';

const IslandScene = (props) => {
    const levelControlsRef = useRef(null);
    const [refresh, setRefresh] = useState(0);
    const [showGameResults, setShowGameResults] = useState(false);
    const [loadingResults, setLoadingResults] = useState(false);
    const readyForNewRound = Game.gameProgress === "submitted" && Game.roundNumber < props.constants.options.aantal_rondes_in_spel;
    const gameEnded = Game.gameProgress === "submitted" && Game.roundNumber === props.constants.options.aantal_rondes_in_spel;
    let timeout = null;

    // Clear timeout on unmount
    useEffect(() => () => {
        if (timeout) {
            clearTimeout(timeout);
        }
    });
    
    /**
     * Method that starts the game, by navigating to the discuss and distribute scene.
     * @param {Event} ev 
     */
    const handleStartGame = (startNewRound = false) => {
        playSound("start-level-animation");
        const islandScene = document.getElementById("island-scene");
        islandScene.classList.add("transition");
        timeout = setTimeout(async () => {
            if (startNewRound) {
                props.dispatch(clearTokenDistribution());
                await Game.startNewRound();
            }
            Game.gameProgress = "discussion";
            props.dispatch(shufflePlayers());
            props.history.push("/bespreken-en-inleggen");
        }, 6000);
    };

    /**
     * Method that transitions the page into showing the final scores.
     */
    const handleShowFinalScores = () => {
        playSound("start-level-animation");
        const islandScene = document.getElementById("island-scene");
        islandScene.classList.add("show-game-results");
        islandScene.classList.add("transition");
        timeout = setTimeout(async () => {
            islandScene.classList.add("showing-game-results");
            setShowGameResults(true);
            if (levelControlsRef && levelControlsRef.current) {
                levelControlsRef.current.classList.add("slideBackIn");
            }
        }, 2500);
    };

    const handleShowResult = async () => {
        setLoadingResults(true);
        const result = await Game.submitTokenDistribution(props.tokenDistribution.distribution);
        if (result === true) {
            await props.reloadGameData(props.store);
            Game.gameProgress = "submitted";
            setRefresh(refresh + 1);
        }
        setLoadingResults(false);
    };

    const handleStartNewRound = async () => {
        handleStartGame(true);
    }

    return (
        <div id="island-scene" className="scene island-scene">

            {/* Level indication for transition */}
            <div id="top-left-level-info" className="top-left-level-info">
                <h3>{__("energy_masters_uppercase")}</h3>
                {gameEnded ? (
                    <h2>{__("eindresultaat")}</h2>
                ) : (
                    <h2>{__("level_uppercase")} 0{Game.gameProgress === "submitted" ? (Game.roundNumber + 1) : Game.roundNumber}</h2>
                )}
            </div>

            {/* Fade out background for transition */}
            <div className="fade-out-background" />

            {/* Card decorations for transition */}
            <div className="island-scene__background__cards__right-top">
                <img src={assets["FLCard"]} alt="card" />
                <img src={assets["GFCard"]} alt="card" />
            </div>
            <div className="island-scene__background__cards__left-bottom">
                <img src={assets["PFCard"]} alt="card" />    
                <img src={assets["FGCard"]} alt="card" />
            </div>

            {/* Loading overlay */}
            {loadingResults ? <LoadingOverlay /> : null}

            <ProgressDashboard refresh={refresh} />

            <div className="island-scene__bottom">
                {/* Show results button */}
                {props.tokenDistribution.readyForSubmit && !readyForNewRound && !gameEnded ? (
                    <div className="controls-container show-result-controls">
                        <div className="controls-container__left-top" />
                        <Token
                            type="GO"
                            onClick={handleShowResult}
                            attachment={__("show_result_label")}
                            pulse
                        />
                        <div className="controls-container__right-top" />
                    </div>
                ) : null}

                {/* Left island state text */}
                <div className="island-scene__bottom__left">
                    <div className="island-scene__bottom__left__content">
                        <p>{__("state_of_the_island_line_pretext")}</p>
                        <div className="line" />
                    </div>
                </div>

                {/* Island */}
                <div className="island-scene__bottom__island">
                    <Island
                        state={props.island[props.island.length - 1].endIslandState || props.island[props.island.length - 1].startIslandState}
                        projects={props.island.reduce((acc, round) => {
                            return acc.concat(round.UitgevoerdProjects.map(p => p.bouwprojectId));
                        }, [])}
                        newProjects={props.island[props.island.length - 1].UitgevoerdProjects.map(p => p.bouwprojectId)}
                        refresh={refresh}
                        globalConsequences={props.consequences.globalConsequences.filter(r => r.ronde === Game.roundNumber).map(r => r.id)}
                    />
                </div>

                {/* Level controls */}
                {(readyForNewRound && !gameEnded) || (!props.tokenDistribution.readyForSubmit && !readyForNewRound && !gameEnded) || (gameEnded) ? (
                    <div ref={levelControlsRef} className="island-scene__bottom__level-controls">
                        <div className="island-scene__bottom__level-controls__corner-left" />
                        {readyForNewRound && !gameEnded ? (
                            <Token
                                label={__("Level %d spelen", Game.roundNumber + 1)}
                                type="GO"
                                onClick={handleStartNewRound}
                                pulse
                            />
                        ) : null}
                        {!props.tokenDistribution.readyForSubmit && !readyForNewRound && !gameEnded ? (
                            <Token
                                label={__("Level %d spelen", 1)}
                                type="GO"
                                onClick={() => { handleStartGame(); }}
                                pulse
                            />
                        ) : null}
                        {gameEnded && !showGameResults ? (
                            <Token
                                label={__("Bekijk eindresultaat")}
                                type="GO"
                                onClick={() => { handleShowFinalScores(); }}
                                pulse
                            />
                        ) : null}
                        {showGameResults ? (
                            <Token
                                label={__("Ga naar start")}
                                type="GO"
                                onClick={() => props.history.push('/')}
                                pulse
                            />
                        ) : null}
                        <div className="island-scene__bottom__level-controls__corner-right" />
                    </div>
                ) : null}

                {showGameResults ? <GameResult /> : null}
            </div>
        </div>
    );
};

export default connect((state) => ({ ...state }))(IslandScene);
