import React, { useState, useEffect, useRef } from 'react';
import {
 BrowserRouter as Router,
 Switch,
 Route,
} from 'react-router-dom';
import '../styles/scss/main.scss';
import i18n, { __ } from '../i18n';
import audio from '../helpers/audio';

// Import scenes
import IntroductionScene from './scenes/IntroductionScene';
import StartScene from './scenes/StartScene';
import IslandScene from './scenes/IslandScene';
import LoadingScene from './scenes/LoadingScene';
import DiscussAndDistributeScene from './scenes/DiscussAndDistributeScene';
import NotFoundScene from './scenes/NotFoundScene';
import PlayerIntroductionScene from './scenes/PlayerIntroductionScene';

// Import middleware
import EnforceAppropriateRoute from './middleware/EnforceAppropriateRoute';
import DataLoader from './middleware/DataLoader';
import applyMiddleware from '../helpers/applyMiddleware';

const preloadAssets = require('../helpers/assets').preloadAssets;

const isScreenSizeNotSupported = () => window.innerWidth < 1280 || window.innerHeight < 720;

i18n.init();
audio.init();

function App({ store }) {
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const screenNotSupportedRef = useRef(null);

  const checkScreenSizeSupport = () => {
    if (screenNotSupportedRef && screenNotSupportedRef.current) {
      if (isScreenSizeNotSupported()) {
        screenNotSupportedRef.current.classList.add("visible");
      } else {
        screenNotSupportedRef.current.classList.remove("visible");
      }
    }
  };

  useEffect(() => {
    preloadAssets().then(() => setAssetsLoaded(true));
    window.addEventListener("resize", checkScreenSizeSupport);
    return () => {
      window.removeEventListener("resize", checkScreenSizeSupport);
    };
  }, []);

  if (!assetsLoaded) return <LoadingScene />;

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={props => applyMiddleware(StartScene, { ...props, store }, [EnforceAppropriateRoute])} />
          <Route exact path="/introductie" component={props => applyMiddleware(IntroductionScene, { ...props, store }, [EnforceAppropriateRoute])} />
          <Route exact path="/speler-introductie" component={props => applyMiddleware(PlayerIntroductionScene, { ...props, store }, [DataLoader])} />
          <Route exact path="/eiland" component={props => applyMiddleware(IslandScene, { ...props, store }, [EnforceAppropriateRoute, DataLoader])} />
          <Route exact path="/bespreken-en-inleggen" component={props => applyMiddleware(DiscussAndDistributeScene, { ...props, store }, [EnforceAppropriateRoute, DataLoader])} />
          <Route component={NotFoundScene} />
        </Switch>
      </Router>
      <div id="loading-overlay-portal" />
      <div id="popup-portal" />
      <div ref={screenNotSupportedRef} className={`screen-not-supported${isScreenSizeNotSupported() ? " visible" : ""}`}>
        <div className="screen-not-supported__inner">
          <h1>{__("not_supported_screen_title")}</h1>
          <p>{__("not_supported_screen_instructions")}</p>
        </div>
      </div>
    </div>
  );
}

export default App;
