import React from 'react';
import Game from '../../classes/Game';
import getAppropriateRoutePath from '../../helpers/getAppropriateRoutePath';

const pathWhitelist = ["/", "/introductie"];

const EnforceAppropriateRoute = (Component, props) => {

    const supposedPath = getAppropriateRoutePath(Game.gameProgress);

    /**
     * Redirect to / if you don't have a game ID yet.
     */
    if (props.history.location.pathname !== '/' && !Game.currentlyPlaying) {
        props.history.push("/");
        return null;
    }

    /**
     * Enforce the appropriate route path
     */
    if (supposedPath && !props.location.pathname.startsWith(supposedPath) && !pathWhitelist.find(p => p === props.location.pathname)) {
        props.history.push(supposedPath);
    }


    return <Component {...props} enforced={true} />;
};

export default EnforceAppropriateRoute;
