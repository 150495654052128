import {
    UPDATE_PROJECTS,
} from '../types';

const initialState = [];

const projects = (state = initialState, action) => {
    const { payload, type } = action;

    switch(type) {
        case UPDATE_PROJECTS:
            return [...payload];
        default:
            return state;
    }
};

export default projects;
