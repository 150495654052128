import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import leftPad from 'left-pad';
import ReactMarkdown from 'react-markdown';
import CustomScroll from 'react-custom-scroll';

import Token from './Token';
import Popup from './Popup';
import PlayerCard from './PlayerCard';

import assets from '../../helpers/assets';
import getTokenTypeName from '../../helpers/getTokenTypeName';

import { playSound } from '../../helpers/audio';
import { __, __mf, getLocale } from '../../i18n';

const ProjectCard = (props) => {
    const [popup, showPopup] = useState(false);
    const allCostsProvidedFor = props.costs.every((cost, i) => {
        return props.tokenDistribution.distribution[props.id].find(c => c.index === i);
    });
    const className = `project-card${props.className ? ` ${props.className}` : ''}${allCostsProvidedFor ? " completed" : ""}`;

    /**
     * Method to render costs as tokens.
     */
    const renderCosts = () => {
        return props.costs.map((cost, i) => {
            const costBelongsTo = props.players.find(p => p.id === cost.spelerId) || {};
            const costBelongsToAlt = props.players.find(p => p.id === cost.spelerAltId) || {};
            const madeMove = props.tokenDistribution.distribution[props.id].find(c => c.index === i);
            return [
                <div
                    id={`project-${props.id}-cost-${i}`}
                    className="project-cost"
                    key={'cost-' + i}
                    onClick={() => {
                        if ((costBelongsTo.id || costBelongsToAlt.id) && props.phase === "discussion") {
                            playSound("button-click");
                            showPopup(`cost-info-${i}`);
                        }
                    }}
                >
                    {costBelongsTo.id && !madeMove ? (
                        <div className="project-cost__belongs-to">
                            <div className={costBelongsTo.id} />
                        </div>
                    ) : null}
                    {costBelongsToAlt.id && !madeMove ? (
                        <div className="project-cost__belongs-to project-cost__belongs-to__alt">
                            <div className={costBelongsToAlt.id} />
                        </div>
                    ) : null}
                    <Token
                        key={`cost-${i}`}
                        type={cost.tokenTypeId}
                        inactive={!madeMove}
                        belongsTo={madeMove ? madeMove.spelerId : null}
                    />
                    {(costBelongsTo.id || costBelongsToAlt.id) && props.phase === "discussion" ? (
                        <div className="project-cost__popup-toggle">
                            <img src={assets['QuestionMark']} alt="question mark" />
                        </div>
                    ) : null}
                </div>,
                <Popup key={'cost-popup-' + i} show={popup === `cost-info-${i}`} name="cost-info" handleClose={() => showPopup(false)}>
                    {costBelongsTo.id && costBelongsToAlt.id ? (
                        <React.Fragment>
                            <div className="card-illustration-container alt">
                                <div className="card-illustration-container__symbol-left" />
                                <div className="card-illustration-container__inner">
                                    <img className="token-icon" src={assets[`${cost.tokenTypeId}Token`]} alt={cost.tokenTypeId} />
                                    <div className="card-container">
                                        <PlayerCard
                                            id={costBelongsTo.id}
                                        />
                                        <Token
                                            type={cost.tokenTypeId}
                                            belongsTo={costBelongsTo.id}
                                        />
                                    </div>
                                    <div className="card-container">
                                        <PlayerCard
                                            id={costBelongsToAlt.id}
                                        />
                                        <Token
                                            type={cost.tokenTypeId}
                                            belongsTo={costBelongsToAlt.id}
                                        />
                                    </div>
                                </div>
                                <div className="card-illustration-container__symbol-right" />
                            </div>
                            <div className="description-container">
                                <p>{__("needed_for_this_project")}</p>
                                <p><ReactMarkdown children={__mf("{tokenTypeName} van {belongsToName} of {belongsToAltName}.", {
                                    tokenTypeName: getTokenTypeName(cost.tokenTypeId, props.constants.tokenTypes, getLocale()),
                                    belongsToName: costBelongsTo.naam,
                                    belongsToAltName: costBelongsToAlt.naam,
                                })} /></p>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="card-illustration-container">
                                <div className="card-illustration-container__symbol-left" />
                                <div className="card-illustration-container__inner">
                                    <PlayerCard
                                        id={costBelongsTo.id || costBelongsToAlt.id}
                                    />
                                    <img className="token-icon" src={assets[`${cost.tokenTypeId}Token`]} alt={cost.tokenTypeId} />
                                    <Token
                                        type={cost.tokenTypeId}
                                        belongsTo={costBelongsTo.id || costBelongsToAlt.id}
                                    />
                                </div>
                                <div className="card-illustration-container__symbol-right" />
                            </div>
                            <div className="description-container">
                                <p>{__("needed_for_this_project")}</p>
                                <p><ReactMarkdown children={__mf("{tokenTypeName} van {belongsToName}.", {
                                    tokenTypeName: getTokenTypeName(cost.tokenTypeId, props.constants.tokenTypes, getLocale()),
                                    belongsToName: costBelongsTo.naam,
                                })} /></p>
                            </div>
                        </React.Fragment>
                    )}
                    <Token label="Sluiten" type="NOK" onClick={() => showPopup(false)} />
                </Popup>
            ];
        });
    };


    /**
     * Method to render yields as tokens.
     */
    const renderYields = () => {
        return props.yields.map((y) => {
            const player = (props.players.find(p => p.id === y.spelerId) || {});
            const playerName = player.naam;
            const amount = y.waarde;
            const tokenTypeName = getTokenTypeName(y.tokenTypeId, props.constants.tokenTypes, getLocale());
            const projectName = props.title || "";
            return [
                <div
                    key={'yield-' + y.id}
                    className="project-card__inner__yields-container__yields__yield"
                    onClick={() => {
                        if (props.phase === "discussion") {
                            playSound("button-click");
                            showPopup(`yield-info-${y.id}`);
                        }
                    }}
                >
                    <Token
                        amount={y.waarde}
                        type={y.tokenTypeId}
                        belongsTo={(props.players.find(p => p.id === y.spelerId) || {}).id}
                    />
                    {props.phase === "discussion" ? (
                        <div className="project-card__inner__yields-container__yields__yield__popup-toggle">
                            <img src={assets['QuestionMark']} alt="question mark" />
                        </div>
                    ) : null}
                </div>,
                <Popup key={'yield-popup-' + y.id} show={popup === `yield-info-${y.id}`} name="yield-info" handleClose={() => showPopup(false)}>
                    <div className="card-illustration-container">
                        <div className="card-illustration-container__symbol-left" />
                        <div className="card-illustration-container__inner">
                            <PlayerCard
                                name={player.name}
                                id={player.id}
                            />
                            <img className="token-icon" src={assets[`${y.tokenTypeId}Token`]} alt={y.tokenTypeId} />
                            <Token
                                type={y.tokenTypeId}
                                belongsTo={player.id}
                            />
                        </div>
                        <div className="card-illustration-container__symbol-right" />
                    </div>
                    <div className="description-container">
                        <p>{__("Door dit project uit te voeren:")}</p>
                        <p><ReactMarkdown children={__mf("{receivesOrLoses} {playerName} een {tokenName}", {
                            receivesOrLoses: amount > 0 ? __("Ontvangt") : __("Verliest"),
                            playerName: playerName,
                            tokenName: tokenTypeName.toLowerCase()
                        })} /></p>
                    </div>
                    <Token label={__("close_button_label")} type="NOK" onClick={() => showPopup(false)} />
                </Popup>
            ];
        });
    };

    return (
        <div className={className} style={props.style}>
            <div className="project-card__completed-overlay">
                <div className="project-card__completed-overlay__inner">
                    <h2>{__("complete_uppercase")}</h2>
                    <hr />
                    <h4>{__("well_done")}</h4>
                </div>
            </div>
            <div className="project-card__cover" style={{ animationDelay: props.style.animationDelay }} />
            <CustomScroll>
                <div className="project-card__inner">
                    <div className="project-card__inner__top">
                        <p>{leftPad(props.number, 2, 0)}</p>
                        <div className="title-info-appendix" />
                        {props.phase === "discussion" ? (<div onClick={() => { playSound("button-click"); showPopup('project-info'); }} className="project-card__info-toggle">{__("info_uppercase")}</div>) : null}
                    </div>
                    <div className="project-card__inner__title-container">
                        <h2>{props.title}</h2>
                    </div>
                    <div className={`project-card__inner__yields-container${props.yields.length ? " active" : ""}`}>
                        <div className="project-card__inner__yields-container__top">
                            <span>{__("yields")}</span>
                            <div className="title-appendix" />
                        </div>
                        <div className="project-card__inner__yields-container__yields">
                            {renderYields()}
                        </div>
                    </div>
                    <div className="project-card__inner__costs-container">
                        <div className="project-card__inner__costs-container__top">
                            <span>{__("costs")}</span>
                            <div className="title-appendix" />
                        </div>
                        <div className="project-card__inner__costs-container__costs">
                            {renderCosts()}
                        </div>
                    </div>
                </div>
            </CustomScroll>
            <Popup show={popup === 'project-info'} name="project-info" handleClose={() => showPopup(false)}>
                <ProjectCard {...props} key={props.id + "-card-preview"} />
                <div className="description-container">
                    <h2>{__("info_uppercase")}</h2>
                    <ReactMarkdown children={props.description} />
                </div>
                <Token label={__("close_button_label")} type="NOK" onClick={() => showPopup(false)} />
            </Popup>
        </div>
    );
};

ProjectCard.propTypes = {
    id: PropTypes.string,
    number: PropTypes.number,
    title: PropTypes.string,
    costs: PropTypes.array,
    yields: PropTypes.array,
    description: PropTypes.string,
    style: PropTypes.object,
    phase: PropTypes.oneOf(["discussion", "distribution"]),
    currentPlayer: PropTypes.shape({}),
};
ProjectCard.defaultProps = {
    style: {},
    currentPlayer: null,
};

export default connect(({ players, tokenDistribution, constants }) => ({ players, tokenDistribution, constants }))(ProjectCard);
