import { UPDATE_TOKEN_DISTRIBUTION, ADD_PAYMENT, SET_READY_FOR_SUBMIT, CLEAR_TOKEN_DISTRIBUTION } from '../types';

export const updateTokenDistribution = (payload) => (dispatch) => {
    dispatch({ type: UPDATE_TOKEN_DISTRIBUTION, payload });
};

export const clearTokenDistribution = () => (dispatch) => {
    dispatch({ type: CLEAR_TOKEN_DISTRIBUTION });
};

export const addPayment = (payment, projectId) => (dispatch) => {
    dispatch({ type: ADD_PAYMENT, payload: { payment, projectId } });
};

export const setReadyForSubmit = (isReady) => (dispatch) => {
    dispatch({ type: SET_READY_FOR_SUBMIT, payload: isReady });
};

export default {
    updateTokenDistribution,
    addPayment,
    setReadyForSubmit,
    clearTokenDistribution,
};
  