import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import config from '../../config';

const middleware = [
    // Logger
    store => next => action => {
        if (config.logging) {
          console.group(action.type);
          console.info('dispatching', action);
        }
        let result = next(action);
        if (config.logging) {
          console.log('next state', store.getState());
          console.groupEnd();
        }
        return result;
    },
    // Allow for async actions
    thunk,
];

const store = createStore(
  rootReducer,
  applyMiddleware(
    ...middleware
  ),
);

export default store;
