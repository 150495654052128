import {
    UPDATE_PROJECTS,
} from '../types';

export const updateProjects = (payload) => (dispatch) => {
    dispatch({ type: UPDATE_PROJECTS, payload });
};

export default {
    updateProjects,
};
  