import _ from 'lodash';
import { UPDATE_PROJECTS, ADD_PAYMENT, SET_READY_FOR_SUBMIT, CLEAR_TOKEN_DISTRIBUTION } from '../types';
import Game from '../../classes/Game';

const initialState = {
    readyForSubmit: false,
    distribution: {},
};

const tokenDistribution = (state = Game.tokenDistribution || _.cloneDeep(initialState), action) => {
    const { payload, type } = action;

    switch(type) {
        case ADD_PAYMENT:
            state.distribution[payload.projectId].push(payload.payment);
            Game.tokenDistribution = JSON.stringify(state);
            return { ...state };
        case SET_READY_FOR_SUBMIT:
            state.readyForSubmit = payload;
            Game.tokenDistribution = JSON.stringify(state);
            return { ...state }
        case CLEAR_TOKEN_DISTRIBUTION:
            const newState = _.cloneDeep(initialState);
            Game.tokenDistribution = JSON.stringify(newState);
            return newState;
        case UPDATE_PROJECTS:
            // Ensure each project has an array to hold token distributions per project.
            payload.forEach((project) => {
                if (!state.distribution[project.id]) {
                    state.distribution[project.id] = [];
                }
            });
            Game.tokenDistribution = JSON.stringify(state);
            return { ...state };
        default:
            return state;
    }
};

export default tokenDistribution;
