import {
    UPDATE_PLAYERS,
    SHUFFLE_PLAYERS,
} from '../types';
import Game from '../../classes/Game';

import shuffleArray from '../../helpers/shuffleArray';

const initialState = [];

const players = (state = initialState, action) => {
    const { payload, type } = action;

    switch(type) {
        case UPDATE_PLAYERS:
            if (Array.isArray(Game.playerOrder)) {
                return Game.playerOrder.reduce((acc, playerId) => {
                    acc.push({ ...payload.find(p => p.id === playerId) });
                    return acc;
                }, []);
            }
            return [...payload];
        case SHUFFLE_PLAYERS:
            const shuffled = shuffleArray([...state]);
            Game.playerOrder = shuffled.map(p => p.id);
            return shuffled;
        default:
            return state;
    }
};

export default players;
