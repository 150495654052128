import React from 'react';
import PropTypes from 'prop-types';
import PlayerCard from './PlayerCard';

const PlayerCardStack = (props) => {
    const activePlayerIndex = props.players.findIndex(p => p.id === props.currentPlayer);
    const beforeActivePlayers = props.players.slice(0, activePlayerIndex);
    const afterActivePlayers = props.players.slice(activePlayerIndex + 1);
    const className = `player-card-stack${props.className ? ` ${props.className}` : ''}`;

    return (
        <div className={className}>
            {props.players.map((player, i) => {
                const beforeActiveIndex = beforeActivePlayers.findIndex(p => p.id === player.id);
                const afterActiveIndex = afterActivePlayers.findIndex(p => p.id === player.id);
                return (
                    <PlayerCard
                        key={player.id}
                        active={activePlayerIndex === i}
                        name={player.naam}
                        id={player.id}
                        style={{
                            zIndex: function() {
                                if (beforeActiveIndex !== -1) return beforeActiveIndex;
                                if (afterActiveIndex !== -1) return [].concat(afterActivePlayers).reverse().findIndex(p => p.id === player.id);
                                return props.players.length;
                            }(),
                            transform: function() {

                                // Left transform
                                let left = 260;
                                if (beforeActiveIndex !== -1)
                                    left -= (([].concat(beforeActivePlayers).reverse().findIndex(p => p.id === player.id) + 1) * 40);
                                if (afterActiveIndex !== -1)
                                    left += ((afterActivePlayers.findIndex(p => p.id === player.id) + 1) * 110);
                                left += "px";

                                // Top transform
                                let top = activePlayerIndex === i ? 0 : 50;
                                if (beforeActiveIndex !== -1)
                                    top += ([].concat(beforeActivePlayers).reverse().findIndex(p => p.id === player.id) * 30);
                                if (afterActiveIndex !== -1)
                                    top += (afterActivePlayers.findIndex(p => p.id === player.id) * 60);
                                top += "px";

                                // Card rotation
                                let deg = 0;
                                if (beforeActiveIndex !== -1)
                                    deg -= (([].concat(beforeActivePlayers).reverse().findIndex(p => p.id === player.id) + 1) * 5);
                                if (afterActiveIndex !== -1)
                                    deg += ((afterActivePlayers.findIndex(p => p.id === player.id) + 1) * 5);
                                return `translate(${left},${top}) rotateZ(${deg}deg)`;
                            }(),
                        }}
                    />
                );
            })}
        </div>
    );
};

PlayerCardStack.propTypes = {
    currentPlayer: PropTypes.string,
    players: PropTypes.array,
    className: PropTypes.string,
};
PlayerCardStack.defaultProps = {
    className: null,
};

export default PlayerCardStack;
