// Island backgrounds
import islandBackgroundLevel1 from '../assets/islandSprites/BASIS-WATERNIVEAU_01.apng';
import islandBackgroundLevel2 from '../assets/islandSprites/BASIS-WATERNIVEAU_02.apng';
import islandBackgroundLevel3 from '../assets/islandSprites/BASIS-WATERNIVEAU_03.apng';
import islandBackgroundLevel4 from '../assets/islandSprites/BASIS-WATERNIVEAU_04.apng';
import islandBackgroundLevel5 from '../assets/islandSprites/BASIS-WATERNIVEAU_05.apng';

// Island sprites
import islandSpriteBIONEW from '../assets/islandSprites/BIO-NEW.apng';
import islandSpriteBIO from '../assets/islandSprites/BIO.apng';
import islandSpriteBLUNEW from '../assets/islandSprites/BLU-NEW.apng';
import islandSpriteBLU from '../assets/islandSprites/BLU.png';
import islandSpriteBOSNEW from '../assets/islandSprites/BOS-NEW.apng';
import islandSpriteBOS from '../assets/islandSprites/BOS.png';
import islandSpriteBOUNEW from '../assets/islandSprites/BOU-NEW.apng';
import islandSpriteBOU from '../assets/islandSprites/BOU.apng';
import islandSpriteGAS1NEW from '../assets/islandSprites/GAS1-NEW.apng';
import islandSpriteGAS1 from '../assets/islandSprites/GAS1.apng';
import islandSpriteGAS2NEW from '../assets/islandSprites/GAS2-NEW.apng';
import islandSpriteGAS2 from '../assets/islandSprites/GAS2.png';
import islandSpriteKERNEW from '../assets/islandSprites/KER-NEW.apng';
import islandSpriteKER from '../assets/islandSprites/KER.apng';
import islandSpriteKOLBASIS from '../assets/islandSprites/KOL-BASIS.apng';
import islandSpriteKOL1NEW from '../assets/islandSprites/KOL1-NEW.apng';
import islandSpriteKOL1 from '../assets/islandSprites/KOL1.png';
import islandSpriteKOL2NEW from '../assets/islandSprites/KOL2-NEW.apng';
import islandSpriteKOL2 from '../assets/islandSprites/KOL2.apng';
import islandSpriteKOL6NEW from '../assets/islandSprites/KOL6-NEW.apng';
import islandSpriteKOL6 from '../assets/islandSprites/KOL6.png';
import islandSpriteKOL5NEW from '../assets/islandSprites/KOL5-NEW.apng';
import islandSpriteKOL5 from '../assets/islandSprites/KOL5.png';
import islandSpriteOPSNEW from '../assets/islandSprites/OPS-NEW.apng';
import islandSpriteOPS from '../assets/islandSprites/OPS.png';
import islandSpriteVARBASIS from '../assets/islandSprites/VAR-BASIS.apng';
import islandSpriteVARNEW from '../assets/islandSprites/VAR-NEW.apng';
import islandSpriteVAR from '../assets/islandSprites/VAR.png';
import islandSpriteVEGBASIS from '../assets/islandSprites/VEG-BASIS.png';
import islandSpriteVEG from '../assets/islandSprites/VEG.png';
import islandSpriteWIN1NEW from '../assets/islandSprites/WIN1-NEW.apng';
import islandSpriteWIN1 from '../assets/islandSprites/WIN1.apng';
import islandSpriteWIN4NEW from '../assets/islandSprites/WIN4-NEW.apng';
import islandSpriteWIN4 from '../assets/islandSprites/WIN4.apng';
import islandSpriteWKR1NEW from '../assets/islandSprites/WKR1-NEW.apng';
import islandSpriteWKR1 from '../assets/islandSprites/WKR1.apng';
import islandSpriteWKR2NEW from '../assets/islandSprites/WKR2-NEW.apng';
import islandSpriteWKR2 from '../assets/islandSprites/WKR2.apng';
import islandSpriteWST2NEW from '../assets/islandSprites/WST2-NEW.apng';
import islandSpriteWST2 from '../assets/islandSprites/WST2.apng';
import islandSpriteZON1NEW from '../assets/islandSprites/ZON1-NEW.apng';
import islandSpriteZON1 from '../assets/islandSprites/ZON1.png';
import islandSpriteZON4NEW from '../assets/islandSprites/ZON4-NEW.apng';
import islandSpriteZON4 from '../assets/islandSprites/ZON4.png';
import islandSpriteZON5NEW from '../assets/islandSprites/ZON5-NEW.apng';
import islandSpriteZON5 from '../assets/islandSprites/ZON5.png';

// Sprites from global consequences
import islandSpriteGlobalConsequence15 from '../assets/islandSprites/GLOBAL-CONSEQUENCE-15.png';
import islandSpriteGlobalConsequence16 from '../assets/islandSprites/GLOBAL-CONSEQUENCE-16.png';
import islandSpriteGlobalConsequence17 from '../assets/islandSprites/GLOBAL-CONSEQUENCE-17.png';

// Other
import Bendline from '../assets/images/png/bendline.png';
import BONUSToken from '../assets/images/png/bonus-token.png';
import BONUSTokenInactive from '../assets/images/png/bonus-token-inactive.png';
import WhiteArrowRight from '../assets/images/png/white-arrow-right.png';
import IslandPreview from '../assets/images/png/island-preview.png';
import QuestionMark from '../assets/images/png/questionmark.png';
import NotSupportedBackgroundAddition from '../assets/images/png/not-supported-background-addition.png';

// Flags
import nl from '../assets/images/png/nl.png';
import en from '../assets/images/png/en.png';

// Player tokens and cards
import FGCard from '../assets/images/png/feel-card.png';
import FGToken from '../assets/images/png/feel-token.png';
import FFCard from '../assets/images/png/food-card.png';
import FFToken from '../assets/images/png/food-token.png';
import FLCard from '../assets/images/png/fut-card.png';
import FLToken from '../assets/images/png/fut-token.png';
import CGCard from '../assets/images/png/gov-card.png';
import GFCard from '../assets/images/png/grand-card.png';
import GFToken from '../assets/images/png/grand-token.png';
import IECard from '../assets/images/png/insol-card.png';
import IICard from '../assets/images/png/isle-card.png';
import IIToken from '../assets/images/png/isle-token.png';
import PFCard from '../assets/images/png/pret-card.png';

// Tokens
import GELDPlaceholder from '../assets/images/png/geld-placeholder.png';
import GELDToken from '../assets/images/png/geld-token.png';
import KENNISPlaceholder from '../assets/images/png/kennis-placeholder.png';
import KENNISToken from '../assets/images/png/kennis-token.png';
import ACTIEToken from '../assets/images/png/actie-token.png';
import ACTIEPlaceholder from '../assets/images/png/actie-placeholder.png';

// Buttons
import GOToken from '../assets/images/png/go-button.png';
import NOKToken from '../assets/images/png/nok-button.png';
import OKToken from '../assets/images/png/ok-button.png';

const islandBackgrounds = {
    islandBackgroundLevel1,
    islandBackgroundLevel2,
    islandBackgroundLevel3,
    islandBackgroundLevel4,
    islandBackgroundLevel5,
};

const islandSprites = {
    islandSpriteBIO,
    islandSpriteBLU,
    islandSpriteBOS,
    islandSpriteBOU,
    islandSpriteGAS1,
    islandSpriteGAS2,
    islandSpriteKER,
    islandSpriteKOLBASIS,
    islandSpriteKOL1,
    islandSpriteKOL2,
    islandSpriteKOL6,
    islandSpriteKOL5,
    islandSpriteOPS,
    islandSpriteVARBASIS,
    islandSpriteVAR,
    islandSpriteVEGBASIS,
    islandSpriteVEG,
    islandSpriteWIN1,
    islandSpriteWIN4,
    islandSpriteWKR1,
    islandSpriteWKR2,
    islandSpriteWST2,
    islandSpriteZON1,
    islandSpriteZON4,
    islandSpriteZON5,

    // New meaning newly build.
    islandSpriteBIONEW,
    islandSpriteBLUNEW,
    islandSpriteBOSNEW,
    islandSpriteBOUNEW,
    islandSpriteGAS1NEW,
    islandSpriteGAS2NEW,
    islandSpriteKERNEW,
    islandSpriteKOL1NEW,
    islandSpriteKOL2NEW,
    islandSpriteKOL6NEW,
    islandSpriteKOL5NEW,
    islandSpriteOPSNEW,
    islandSpriteVARNEW,
    islandSpriteWIN1NEW,
    islandSpriteWIN4NEW,
    islandSpriteWKR1NEW,
    islandSpriteWKR2NEW,
    islandSpriteWST2NEW,
    islandSpriteZON1NEW,
    islandSpriteZON4NEW,
    islandSpriteZON5NEW,

    // Sprites from global consequences,
    islandSpriteGlobalConsequence15,
    islandSpriteGlobalConsequence16,
    islandSpriteGlobalConsequence17,
};

const _assets = {
    Bendline,
    BONUSToken,
    BONUSTokenInactive,
    WhiteArrowRight,
    FGCard,
    FGToken,
    FFCard,
    FFToken,
    FLCard,
    FLToken,
    CGCard,
    GFCard,
    GFToken,
    IECard,
    IICard,
    IIToken,
    PFCard,
    GELDPlaceholder,
    GELDToken,
    KENNISPlaceholder,
    KENNISToken,
    ACTIEToken,
    ACTIEPlaceholder,
    GOToken,
    NOKToken,
    OKToken,
    IslandPreview,
    QuestionMark,
    NotSupportedBackgroundAddition,
    ENToken: en,
    NLToken: nl,
    ...islandBackgrounds,
    ...islandSprites,
};

const assets = {
    ..._assets,
    IEToken: FLToken, // duplicate
    PFToken: GFToken, // duplicate
    CGToken: GFToken, // duplicate
    $Token: GELDToken, // Alias
    AToken: ACTIEToken, // Alias
    KToken: KENNISToken, // Alias
    $Placeholder: GELDPlaceholder, // alias
    APlaceholder: ACTIEPlaceholder, // alias
    KPlaceholder: KENNISPlaceholder, // alias
};

export const preloadAssets = async () => {
    return Promise.all(Object.entries(_assets).map(async ([name, src]) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.setAttribute("src", src);
            img.setAttribute("alt", name);
            img.onload = function() {
                resolve();
            }
        });
    }));
};

export default assets;
