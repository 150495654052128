import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import config from '../../config';
import Game from '../../classes/Game';
import Timer from './Timer';
import Token from './Token';
import Popup from './Popup';
import { __ } from '../../i18n';

let timerClickTimeout = null;
const DiscussControls = (props) => {
    const [numClicksTimer, setNumClicksTimer] = useState(0);
    const [popup, showPopup] = useState(null);

    useEffect(() => {
        if (numClicksTimer >= 3) {
            showPopup("pause-timer");
        }
    }, [numClicksTimer]);

    /**
     * Method to handle end of timer.
     */
    const handleTimerEnd = () => {
        if (config.logging) {
            console.log("Timer ended for discussion phase!");
        }
        Game.setTimerProgress("discussion", props.constants.options.overleg_tijd_ms);
        showPopup(null);
        props.onDiscussionEnded();
    };

    const handleTimerClick = () => {
        if (timerClickTimeout) {
            clearTimeout(timerClickTimeout);
        }
        setNumClicksTimer(numClicksTimer + 1);
        timerClickTimeout = setTimeout(() => {
            setNumClicksTimer(0);
        }, 300);
    };

    return (
        <div className="controls-container discuss-controls">
            <div className="controls-container__left-top" />
            <Timer
                onClick={handleTimerClick}
                onEnd={handleTimerEnd}
                phase="discussion"
                duration={props.constants.options.overleg_tijd_ms}
                paused={!props.closedDiscussExplanation || popup === 'pause-timer'}
            />
            <Token
                type="OK"
                attachment={__("discussion_done_label")}
                onClick={() => showPopup("skip-discuss")}
            />

            <Popup show={popup === 'skip-discuss'} name={popup} handleClose={() => showPopup(null)}>
                <h2>{__("skip_discuss_popup_title")}</h2>
                <p>{__("skip_discuss_popup_description")}</p>
                <div className="button-controls">
                    <Token label={__("done_button_label")} type="OK" onClick={handleTimerEnd} />
                    <Token label={__("close_button_label")} type="NOK" onClick={() => showPopup(null)} />
                </div>
            </Popup>
            <Popup show={popup === 'pause-timer'} name={popup} handleClose={() => showPopup(null)}>
                <h2>{__("pause_timer_popup_title")}</h2>
                <p>{__("pause_timer_popup_description")}</p>
                <div className="button-controls">
                    <Token label={__("close_button_label")} type="NOK" onClick={() => showPopup(null)} />
                </div>
            </Popup>
            <div className="controls-container__right-top" />
        </div>
    );
};

DiscussControls.propTypes = {
    onDiscussionEnded: PropTypes.func,
    closedDiscussExplanation: PropTypes.bool,
};
DiscussControls.defaultProps = {
    closedDiscussExplanation: false
};

export default connect(({ constants }) => ({ constants }))(DiscussControls);
