import { Howl } from 'howler';

import config from '../config';

// Import sounds
import StartLevelAnimationSound from '../assets/audio/mp3/start-level-animation.mp3';
import BonusMoveMadeSound from '../assets/audio/mp3/bonus-move-made.mp3';
import CardSwapSound from '../assets/audio/mp3/card-swap.mp3';
import ButtonClickSound from '../assets/audio/mp3/button-click.mp3';
import TimerEndingSound from '../assets/audio/mp3/timer-ending.mp3';
import ProjectCompletedSound from '../assets/audio/mp3/project-completed.mp3';
import TokenPlacedSound from '../assets/audio/mp3/token-placed.mp3';
import WalletEffect from '../assets/audio/mp3/wallet-effect.mp3';
import NewSprite from '../assets/audio/mp3/new-sprite.mp3';

let sounds = [
    { name: 'start-level-animation', sound: StartLevelAnimationSound, Howl: null, loop: false },
    { name: 'bonus-move-made', sound: BonusMoveMadeSound, Howl: null, loop: false },
    { name: 'card-swap', sound: CardSwapSound, Howl: null, loop: false },
    { name: 'button-click', sound: ButtonClickSound, Howl: null, loop: false },
    { name: 'timer-ending', sound: TimerEndingSound, Howl: null, loop: true },
    { name: 'project-completed', sound: ProjectCompletedSound, Howl: null, loop: false },
    { name: 'token-placed', sound: TokenPlacedSound, Howl: null, loop: false },
    { name: 'wallet-effect', sound: WalletEffect, Howl: null, loop: false },
    { name: 'new-sprite', sound: NewSprite, Howl: null, loop: false },
];

export const init = () => {
    sounds = sounds.map((s) => {
        return {
            ...s,
            Howl: new Howl({
                src: [s.sound],
                loop: s.loop,
            }),
        };
    });
};

export const playSound = (soundName) => {
    const sound = sounds.find(s => s.name === soundName);
    if (!sound) return null;
    if (config.logging) {
        console.log("Playing sound:", sound);
    }
    return sound.Howl.play();
};

export const stopSound = (soundName, soundId) => {
    const sound = sounds.find(s => s.name === soundName);
    if (!sound) return null;
    if (config.logging) {
        console.log("Stopping sound:", soundId, sound);
    }
    return sound.Howl.stop(soundId);
};

export default {
    init,
    playSound,
    stopSound,
};
