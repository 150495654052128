// Option types
export const UPDATE_CONSTANTS = "UPDATE_CONSTANTS";

// Consequences types
export const UPDATE_CONSEQUENCES = "UPDATE_CONSEQUENCES";
export const UPDATE_GLOBAL_CONSEQUENCES = "UPDATE_GLOBAL_CONSEQUENCES";

// Player types
export const UPDATE_PLAYERS = "UPDATE_PLAYERS";
export const SHUFFLE_PLAYERS = "SHUFFLE_PLAYERS";

// Project types
export const UPDATE_PROJECTS = "UPDATE_PROJECTS";

// Island types
export const UPDATE_ISLAND_STATE = "UPDATE_ISLAND_STATE";

// Token distribution types
export const UPDATE_TOKEN_DISTRIBUTION = "UPDATE_TOKEN_DISTRIBUTION";
export const ADD_PAYMENT = "ADD_PAYMENT";
export const SET_READY_FOR_SUBMIT = "SET_READY_FOR_SUBMIT";
export const CLEAR_TOKEN_DISTRIBUTION = "CLEAR_TOKEN_DISTRIBUTION";

export default {
    UPDATE_CONSTANTS, 
    UPDATE_CONSEQUENCES,
    UPDATE_GLOBAL_CONSEQUENCES,
    UPDATE_PLAYERS,
    UPDATE_PROJECTS,
    UPDATE_ISLAND_STATE,
    UPDATE_TOKEN_DISTRIBUTION,
    SET_READY_FOR_SUBMIT,
};
