import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Game from '../../classes/Game';
import Timer from './Timer';
import Token from './Token';
import Popup from './Popup';
import { __ } from '../../i18n';

const DistributeControls = (props) => {
    const [popup, showPopup] = useState(null);

    useEffect(() => {
        showPopup(null);
    }, [props.currentPlayer]);
    useEffect(() => {}, [props.closedDistributeExplanation]);

    /**
     * Method to handle end of timer.
     */
    const handleTimerEnd = () => {
        Game.setTimerProgress("distribution", props.constants.options.inzet_tijd_per_speler_ms);
        showPopup(null);
        props.onDistributeEnded();
    };

    return (
        <div className="controls-container distribute-controls">
            <div className="controls-container__left-top" />
            <Timer
                onEnd={handleTimerEnd}
                phase="distribution"
                duration={props.constants.options.inzet_tijd_per_speler_ms}
                paused={!props.closedDistributeExplanation}
            />
            <Token
                type="NOK"
                attachment={__("skip_turn_button_label")}
                onClick={() => showPopup("skip-distribute")}
            />

            <Popup show={popup === 'skip-distribute'} name={popup} handleClose={() => showPopup(null)}>
                <h2>{__("skip_distribute_popup_title")}</h2>
                <p>{__("skip_distribute_popup_description")}</p>
                <div className="button-controls">
                    <Token label={__("skip_button_label")} type="OK" onClick={handleTimerEnd} />
                    <Token label={__("close_button_label")} type="NOK" onClick={() => showPopup(null)} />
                </div>
            </Popup>
            <div className="controls-container__right-top" />
        </div>
    );
};

DistributeControls.propTypes = {
    onDistributeEnded: PropTypes.func,
    currentPlayer: PropTypes.string,
    closedDistributeExplanation: PropTypes.bool,
};
DistributeControls.defaultProps = {
    closedDistributeExplanation: false,
};

export default connect(({ constants }) => ({ constants }))(DistributeControls);
