import React from 'react';
import ReactDOM from 'react-dom';

import Loader from './Loader';

import { __ } from '../../i18n';

const LoadingOverlay = (props) => {
    const portal = document.getElementById('loading-overlay-portal');

    return ReactDOM.createPortal((
        <div className="loading-overlay">
            <div className="loader-container">
                <Loader theme="light" />
                <p>{__("loading...")}</p>
            </div>
        </div>
    ), portal);
};

export default LoadingOverlay;
