import {
    UPDATE_CONSEQUENCES,
    UPDATE_GLOBAL_CONSEQUENCES,
} from '../types';

export const updateConsequences = (payload) => (dispatch) => {
    dispatch({ type: UPDATE_CONSEQUENCES, payload });
};

export const updateGlobalConsequences = (payload) => (dispatch) => {
    dispatch({ type: UPDATE_GLOBAL_CONSEQUENCES, payload });
};

export default {
    updateConsequences,
    updateGlobalConsequences,
};
  