import {
    UPDATE_CONSEQUENCES,
    UPDATE_GLOBAL_CONSEQUENCES,
} from '../types';

import Game from '../../classes/Game';

const initialState = {
    consequences: [],
    consequencesThisRound: [],
    consequencesPrevRound: [],
    globalConsequences: [],
    globalConsequencesThisRound: [],
    globalConsequencesPrevRound: [],
};

const consequences = (state = initialState, action) => {
    const { payload, type } = action;

    switch(type) {
        case UPDATE_CONSEQUENCES:
            return {
                ...state,
                consequences: [...payload],
                consequencesThisRound: [...payload].filter(c => c.roundNumber === Game.roundNumber),
                consequencesPrevRound: [...payload].filter(c => c.roundNumber === (Game.roundNumber - 1))
            };
        case UPDATE_GLOBAL_CONSEQUENCES:
            return {
                ...state,
                globalConsequences: [...payload],
                globalConsequencesThisRound: [...payload].filter(c => c.ronde === Game.roundNumber),
                globalConsequencesPrevRound: [...payload].filter(c => c.ronde === (Game.roundNumber - 1))
            };
        default:
            return state;
    }
};

export default consequences;
