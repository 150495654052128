import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PlayerWithWallet from './PlayerWithWallet';
import PlayerCard from './PlayerCard';
import { __, getLocale } from '../../i18n';
import { playSound } from '../../helpers/audio';

const PlayersSlider = (props) => {
    let timeouts = [];
    const itemsPerSlide = 4;
    const tokenConsequences = props.consequences.consequencesPrevRound.filter(c => c.consequentieTypeId === 'TOKEN');
    const globalTokenConsequences = props.consequences.globalConsequencesPrevRound.filter(c => c.globaleConsequentieTypeId === 'TOKEN');

    const _playerWallets = props.players.reduce((acc, player) => {
        acc[player.id] = { ...player.wallet };
        return acc;
    }, {});

    // Extract consequences that happened from wallets, so we can animate them on there later
    props.players.forEach(({ id }) => {

        // Consequences
        const playerTokenConsequences = tokenConsequences.filter(c => c.spelerId === id);
        playerTokenConsequences.forEach(c => _playerWallets[id][c.tokenTypeId] -= c.waarde);    

        // Global consequences
        const playerTokenGlobalConsequences = globalTokenConsequences.filter(c => c.spelerId === id);
        playerTokenGlobalConsequences.forEach(c => _playerWallets[id][c.tokenTypeId] -= c.waarde);
    });

    const [slide, setSlide] = useState(0);
    const [playerWallets, setPlayerWallets] = useState(_playerWallets);
    const className = 'players-slider';

    useEffect(() => {
        // Clear timeouts when unmounting
        return () => timeouts.forEach(t => clearTimeout(t));
    }, []);
    useEffect(() => {}, [slide, playerWallets]);

    useEffect(() => {
        if (props.animateEffects && !props.introduction) {
            // Animation sequence to add consequences to tokens
            let playerI = 0;
            props.players.forEach(({ id }, y) => {
                // Concatinated with global consequences, so we animate those as well and combine them
                const playerTokenConsequences = globalTokenConsequences
                    .concat(tokenConsequences)
                    .filter(c => c.spelerId === id)
                    .reduce((combined, c) => {
                        const exists = combined.find(com => com.tokenTypeId === c.tokenTypeId)
                        if (exists) {
                            exists.waarde += c.waarde;
                        } else {
                            combined.push(c);
                        }
                        return combined;
                    }, []);
                if (playerTokenConsequences.length) {
                    playerI++;
                }
                timeouts = playerTokenConsequences.map((c, i) => {
                    return setTimeout(() => {
                        if (window.innerHeight < 975) {
                            setSlide(y < 4 ? 0 : 1);
                        }
                        playerWallets[id][c.tokenTypeId] += c.waarde;
                        setPlayerWallets({ ...playerWallets });
                    }, ((playerI * (i + 1)) * 1000));
                });
            });
        }
    }, [props.animateEffects]);

    const handleChangeSlide = () => {
        playSound("button-click");
        setSlide(Math.abs(slide - 1));
    };

    return (
        <div className={className}>
            <div className="players-slider__inner">
                <div className="items-container">
                    <div className="items">
                        {props.players.map((player, i) => {
                            const startIndex = (slide * 4);
                            const endIndex = (startIndex + 4);
                            const active = i < endIndex && i >= startIndex;
                            return (
                                <PlayerWithWallet
                                    key={`player-with-wallet-${slide}-${i}`}
                                    className={!active ? "hide" : ""}
                                    name={player.naam}
                                    id={player.id}
                                    wallet={props.introduction ? player.wallet : playerWallets[player.id]}
                                    i={i}
                                    introduction={props.introduction}
                                    description={getLocale() === "en" ? player.omschrijvingEN : player.omschrijving}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="players-slider__controls" onClick={handleChangeSlide}>
                <div className="players-slider__controls__cards">
                    {props.players.slice((Math.abs(slide - 1)) * itemsPerSlide, (Math.abs(slide - 1) * itemsPerSlide) + itemsPerSlide).map((player, i) => {
                        return (
                            <PlayerCard
                                key={`player-card-${slide}-${player.id}`}
                                name={player.naam}
                                id={player.id}
                                style={function() {
                                    const styles = {};
                                    if (i === 0) {
                                        styles.width = '62px';
                                        styles.zIndex = 0;
                                        styles.transform = 'translateX(-50%) rotate(-10deg)';
                                        styles.left = 'calc(50% - 60px)';
                                    }
                                    if (i === 1) {
                                        styles.width = '66px';
                                        styles.zIndex = 1;
                                        styles.transform = 'translateX(-50%) rotate(-5deg)';
                                        styles.left = 'calc(50% - 20px)';
                                    }
                                    if (i === 2) {
                                        styles.width = '70px';
                                        styles.zIndex = 2;
                                        styles.transform = 'translateX(-50%) rotate(0deg)';
                                        styles.left = 'calc(50% + 20px)';
                                    }
                                    if (i === 3) {
                                        styles.width = '62px';
                                        styles.zIndex = 1;
                                        styles.transform = 'translateX(-50%) rotate(10deg)';
                                        styles.left = 'calc(50% + 60px)';
                                    }
                                    return styles;
                                }()}
                            />
                        );
                    })}
                </div>
                <p>{__("Players")} <span>4/8</span></p>
            </div>
        </div>
    );
};

PlayersSlider.propTypes = {
    animateEffects: PropTypes.bool,
    introduction: PropTypes.bool,
};
PlayersSlider.defaultProps = {
    animateEffects: false,
    introduction: false,
};

export default connect(({ players, consequences }) => ({ players, consequences }))(PlayersSlider);
