import { combineReducers } from 'redux';

import constants from './constants';
import players from './players';
import projects from './projects';
import island from './island';
import tokenDistribution from './tokenDistribution';
import consequences from './consequences';

export default combineReducers({
    constants,
    players,
    projects,
    island,
    tokenDistribution,
    consequences,
});
