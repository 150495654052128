import React from 'react';
import PropTypes from 'prop-types';
import { __ } from '../../i18n';

const Loader = (props) => {
    const className = `loader ${props.theme === 'light' ? 'light' : 'dark'}`;
    return (
        <div className={className}>{__("loading...")}</div>
    );
};

Loader.propTypes = {
    theme: PropTypes.string, // dark or light
};
Loader.defaultProps = {
    theme: 'dark'
};

export default Loader;
