import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Token from './Token';
import PlayerCard from './PlayerCard';
import { playSound } from '../../helpers/audio';

const PlayerWithWallet = (props) => {
    const [prevWallet, setPrevWallet] = useState({ ...props.wallet });
    const className = `player-with-wallet${props.className ? ` ${props.className}` : ''}`;
    const animationDelay = props.i > 3 ? (props.i - 4) * 100 : props.i * 100;

    useEffect(() => {
        let changedTokenTypeId = null;
        Object.entries(prevWallet).find(([tokenTypeId, val]) => {
           if (props.wallet[tokenTypeId] !== val) {
            changedTokenTypeId = tokenTypeId;
               return true;
           }
           return false; 
        });
        if (changedTokenTypeId) {
            const tokenElement = document.querySelector(`.wallet-token-${props.id}-${changedTokenTypeId === '$' ? 'G' : changedTokenTypeId}`);
            const tokenElementContainer = tokenElement.parentNode;
            const tokenAmountHolder = tokenElementContainer.querySelector('.token__amount');
            tokenElementContainer.classList.add("apply-consequence");
            if (tokenAmountHolder) {
                tokenAmountHolder.innerHTML = `<span>${prevWallet[changedTokenTypeId]}</span>`;
            }
            
            setTimeout(() => {
                playSound('wallet-effect');
                const effectNode = document.createElement("SPAN");
                effectNode.classList.add("effect");
                const diff = props.wallet[changedTokenTypeId] - prevWallet[changedTokenTypeId];
                effectNode.innerText = `${diff > 0 ? "+" : "-"}${Math.abs(diff)}`;
                tokenElementContainer.appendChild(effectNode);
                if (tokenAmountHolder) {
                    tokenAmountHolder.innerHTML = `<span>${props.wallet[changedTokenTypeId]}</span>`;
                }
                setTimeout(() => {
                    tokenElementContainer.removeChild(effectNode);
                    tokenElementContainer.classList.remove("apply-consequence");
                }, 400);
            }, 400);
            setPrevWallet(props.wallet);
        }
    });

    return (
        <div className={className} style={{ ...props.style }}>
            {props.introduction ? (
                <div className="player-introduction-text">
                    <h2>{props.name}</h2>
                    <p><ReactMarkdown children={props.description} /></p>
                </div>
            ) : null}
            <div className="player-with-wallet__card-container" style={{ animationDelay: animationDelay + "ms" }}>
                <PlayerCard
                    name={props.name}
                    id={props.id}
                    style={{ animationDelay: animationDelay + "ms" }}
                />
            </div>
            <div className="player-with-wallet__wallet">
                <div className="player-with-wallet__wallet__pre" />
                <Token
                    className={`wallet-token-${props.id}-A`}
                    inactive={!props.wallet.A}
                    type="A"
                    amount={props.wallet.A}
                    belongsTo={props.id}
                    style={{ animationDelay: animationDelay + "ms" }}
                    // style={{ animationDelay: "0ms" }}
                />
                <Token
                    className={`wallet-token-${props.id}-G`}
                    inactive={!props.wallet.$}
                    type="$"
                    belongsTo={props.id}
                    amount={props.wallet.$}
                    style={{ animationDelay: (animationDelay + 100) + "ms" }}
                    // style={{ animationDelay: "100ms" }}
                />
                <Token
                    className={`wallet-token-${props.id}-K`}
                    inactive={!props.wallet.K}
                    type="K"
                    belongsTo={props.id}
                    amount={props.wallet.K}
                    style={{ animationDelay: (animationDelay + 200) + "ms" }}
                    // style={{ animationDelay: "200ms" }}
                />
            </div>
        </div>
    );
};

PlayerWithWallet.propTypes = {
    id: PropTypes.oneOf(["CG", "GF", "PF", "IE", "FL", "II", "FF", 'FG']),
    name: PropTypes.string,
    wallet: PropTypes.shape({ K: PropTypes.number, $: PropTypes.number, A: PropTypes.number }),
    i: PropTypes.number,
    style: PropTypes.object,
    introduction: PropTypes.bool,
    description: PropTypes.string,
};
PlayerWithWallet.defaultProps = {
    i: 0,
    style: {},
    introduction: false,
    description: ""
};

export default PlayerWithWallet;
