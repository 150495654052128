import React, { useState } from 'react';

import assets from '../../helpers/assets';
import { clearTokenDistribution } from '../../redux/actions/tokenDistribution';
import { __, setLocale } from '../../i18n';

import Game from '../../classes/Game';
import Token from '../elements/Token';
import Island from '../elements/Island';

const StartScene = (props) => {
    const [lang, setLang] = useState(null);

    return (
        <div className="scene start-scene">
            <h1 className="title">{__("energy_masters_uppercase")}</h1>
            <h4 className="subtitle">{__("the_power_of_working_together_uppercase")}</h4>
            <div className="start-scene__background__blue" />
            <Island />
            <div className="start-scene__background__cards__right-top">
                <img src={assets["IICard"]} alt="card" />
                <img src={assets["FFCard"]} alt="card" />
            </div>
            <div className="start-scene__background__cards__left-bottom">
                <img src={assets["FLCard"]} alt="card" />    
                <img src={assets["CGCard"]} alt="card" />
            </div>

            {lang ? (
                <div className="start-scene__level-controls start-game">
                    <div className="start-scene__level-controls__corner-left" />
                        <Token
                            type="GO"
                            label={__("start_game_button_label")}
                            onClick={() => {
                                Game.startNewGame()
                                    .then((gameId) => {
                                        if (gameId) {
                                            props.store.dispatch(clearTokenDistribution());
                                            props.history.push('/introductie');
                                        } else {
                                            alert(__("create_game_error"));
                                        }
                                    });
                            }}
                            pulse
                        />
                    <div className="start-scene__level-controls__corner-right" />
                </div>
            ) : null}

            {!lang ? (
                <div className="start-scene__level-controls select-lang">
                    <div className="start-scene__level-controls__corner-left" />
                        <Token
                            type="NL"
                            onClick={() => {
                                setLang(setLocale("nl"));
                            }}
                            style={{ marginRight: '20px' }}
                        />
                        <Token
                            type="EN"
                            label={"Language"}
                            onClick={() => {
                                setLang(setLocale("en"));
                            }}
                        />
                    <div className="start-scene__level-controls__corner-right" />
                </div>
            ) : null}

        </div>
    );
};

export default StartScene;
