import i18n, { __, __mf, __n, __m } from 'i18n-for-browser';

// Import languages
import nl from './nl.json';
import en from './en.json';

export const locales = { nl, en };

export { __, __mf, __n, __m };

export const init = () => {
    
    // Configure i18n locales
    i18n.configure({
        locales,
        defaultLocale: 'nl',
        cookieName: 'lang',
        queryParameter: 'lang'
    });
};

export const getLocale = () => {
    return i18n.getLocale();
};

export const setLocale = (locale = 'nl') => {
    if (locales[locale]) {
        i18n.setLocale(locale);
    }
    return getLocale();
};

export default { init, locales, __, __n, __m, __mf, getLocale, setLocale };
