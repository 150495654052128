import React from 'react';
import PropTypes from 'prop-types';

import assets from '../../helpers/assets';

const PlayerCard = (props) => {
    const className = `player-card${props.className ? ` ${props.className}` : ''} ${props.id} ${props.active ? "active" : "inactive"}`;

    return (
        <div className={className} style={{ ...props.style }}>
            <div className="player-card__inner">
                <img src={assets[`${props.id}Card`]} alt="player card" />
            </div>
        </div>
    );
};

PlayerCard.propTypes = {
    id: PropTypes.oneOf(["CG", "GF", "PF", "IE", "FL", "II", "FF", "FG"]),
    name: PropTypes.string,
    active: PropTypes.bool,
    style: PropTypes.object,
};
PlayerCard.defaultProps = {
    active: false,
    style: {},
};

export default PlayerCard;
