import React from 'react';
import Loader from '../elements/Loader';
import { __ } from '../../i18n';

const LoadingScene = (props) => {
    return (
        <div className="scene loading-scene">
            <div className="loader-container">
                <Loader theme="light" />
                <p>{__("loading...")}</p>
            </div>
        </div>
    );
};

export default LoadingScene;
