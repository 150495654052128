import React, { useEffect, useRef } from 'react';
import Game from '../../classes/Game';


const LineChartRow = (props) => {
    const levels = [...Array(props.levels).keys()];
    let columnRefs = [];
    levels.forEach(() => columnRefs.push(React.createRef()));
    columnRefs = useRef(columnRefs);
    const timeouts = [];

    // Clear timeout on unmount
    useEffect(() => () => {
        if (Array.isArray(timeouts) && timeouts.length) {
            timeouts.forEach(clearTimeout);
        }
    });

    const drawLines = async () => {
        // Reset timeouts
        if (Array.isArray(timeouts) && timeouts.length) {
            timeouts.forEach(clearTimeout);
        }
        for(const i of levels) {
            if (columnRefs.current && columnRefs.current[i] && (!isNaN(props.data[i + 1]) && props.data[i + 1] !== null) && (!isNaN(props.data[i]) && props.data[i] !== null)) {
                const fromData = props.data[i];
                const toData = props.data[i + 1];
                const diff = props.max - fromData;
                const fromToDiff = fromData - toData;
                const topPercentage = (100 / props.max) * diff;

                // Linker zeide
                const maxHoogte = columnRefs.current[i].current.offsetHeight; // pixel hoogte
                const driehoekBreedte = columnRefs.current[i].current.offsetWidth; // pixel breedte
                const driehoekHoogte = (maxHoogte / props.max) * fromToDiff;

                // Stelling van pythagoras (a2 + b2 = c2)
                const lijnLengte = Math.sqrt(Math.pow(driehoekHoogte, 2) + Math.pow(driehoekBreedte, 2));
                const hoek = Math.asin(driehoekHoogte / lijnLengte) * 180/Math.PI;
            
                // Create line
                const lineDiv = document.createElement("DIV");
                lineDiv.classList.add('line-chart__row__col__line');
                if (i === 0) lineDiv.classList.add('first');
                lineDiv.style.animationDelay = `${i * 500}ms`;
                lineDiv.style.top = `${topPercentage}%`;
                lineDiv.style.transform = `rotateZ(${hoek}deg)`;
                if (columnRefs.current[i].current.getElementsByClassName("line-chart__row__col__line")[0]) {
                    columnRefs.current[i].current.removeChild(columnRefs.current[i].current.getElementsByClassName("line-chart__row__col__line")[0]);
                }


                columnRefs.current[i].current.appendChild(lineDiv);
                lineDiv.style.width = '0px';
                timeouts.push(setTimeout(() => {
                    lineDiv.style.width = `${lijnLengte}px`;

                    setTimeout(() => {
                        // Create value label append into line
                        const valueLabel = document.createElement("span");
                        valueLabel.classList.add("line-chart__row__col__line__label");
                        valueLabel.innerText = Number.isInteger(toData) ? toData : toData.toFixed(1);
                        valueLabel.style.transform = `rotateZ(${-hoek}deg)`;
                        lineDiv.appendChild(valueLabel);
                    }, 350);
                }, (i + 1) * 350));
            }
        }
    };

    useEffect(() => {
        drawLines();
        window.addEventListener('resize', drawLines);
        return () => {
            window.removeEventListener('resize', drawLines);
        };
    });

    return (
        <div className="line-chart__row" style={{ zIndex: props.zIndex ? props.zIndex : 0 }}>
        <div className="line-chart__row__title-container">
            <h3 dangerouslySetInnerHTML={{ __html: props.label }} />
        </div>
        <div className="line-chart__row__graph-container">
            <div className="line-chart__row__pre">
                <div className="line-chart__row__pre__max-value">
                    <span>{props.hidePreLabels ? null : props.max}{props.preLabelsSuffix}</span>
                </div>
                {props.type === 'energie' ? (
                    <div className="additional_data_point">
                        <span>100{props.preLabelsSuffix}</span>
                    </div>
                ) : null}
                <div className="line-chart__row__pre__min-value">
                    <span>{props.hidePreLabels ? null : 0}{props.preLabelsSuffix}</span>
                </div>
            </div>
            {levels.map((i) => {
                const withPrognose = Game.gameProgress === "submitted" ? i === Game.roundNumber : i === (Game.roundNumber - 1);
                return (
                    <div style={{ zIndex: levels.length - i }} key={i} ref={columnRefs.current[i]} className={`line-chart__row__col${withPrognose ? " prognose" : ""}`}>
                        {props.type === 'energie' ? <div className="additional_data_point" /> : null}
                    </div>
                );
            })}
        </div>
    </div>
    );
};

export default LineChartRow;
